@import 'src/index.scss';

.navbar-logo {
    position: absolute;
    top: 50%;
    left: 5rem;
    transform: translateY(-50%);

    @media (max-width: 770px) {
        transform: scale(.85) translateY(-40%);
        left: 7rem
    }
    @media (max-width: 390px) {
        left: 4rem;
        transform: scale(.75) translateY(-60%);
    }

    @media (max-width: 350px) {
        display: none
    }
}
.navbar-logo-sm {
    display: none;

    @media (max-width: 350px) {
        display: block;
        left: 5rem;
        transform: scale(.6) translateY(-80%);
    }

    @media (max-width: 307px) {
        display: block;
        left: 4rem;
        transform: scale(.5) translateY(-100%);
    }
}
.page-title {
    font-size: 2rem;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    text-transform: capitalize;
    transform: translate(-50%, -50%);

    @media (max-width: 770px) {
        display: none;
    }
}
.menu-wrapper {
    @include flex-row;
    position: absolute;
    top: 50%;
    right: 3.5rem;
    transform: translateY(-50%);

    @media (max-width: 390px) {
        right: 1.5rem
    }
}
.menu-icon {
    font-size: 2rem;
    font-weight: 700;
    margin-right: 3rem;

    @media (max-width: 770px) {
        margin-right: 1.5rem;
    }
}
.fin-advice-container {
    cursor: pointer;
    
    @media (max-width: 770px) {
        margin-right: 1.5rem !important;
    }
}
.bell-icon {
    font-size: 1.8rem
}
.bell-wrapper {
    position: relative;

    span {
        width: 1.3rem;
        height: 1.3rem;
        font-size: .8rem;
        border-radius: 50%;
        background-color: #ff0062;
        display: grid;
        place-items: center;
        position: absolute;
        top: -.2rem;
        right: 2.5rem;

        @media (max-width: 770px) {
            right: 1rem
        }
    }
}
.user-block {
    @include flex-row;
    margin-left: 1rem;

    span {
        color: #FED0B7;
        font-weight: 700
    }
}
.user-img-wrapper {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: #fff;
    display: grid;
    place-items: center;
    margin-right: 1rem;

    img {
        @media (max-width: 770px) {
            transform: scale(.85)
        }
    }
}

.agreement-title {
    
    @media (max-width: 430px) {
        margin-right: 3rem !important;
    }
}

.abs-right-center {
    position: absolute;
    top: 50%;
    right: 0rem;
    transform: translateY(-50%);
    color: #FED0B7;
    font-size: 1.7rem;

    @media (max-width: 430px) {
        right: 0rem;
        top: 0.5rem
    }
}

.agreement-address-wrapper {
    @media (max-width: 700px) {
        display: flex !important;
        flex-direction: column !important;
        justify-content: flex-start;
        align-items: flex-start;
    }
    div {
        @media (max-width: 700px) {
            width: 100%;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 2rem;
        }
        
        span {
            @media (max-width: 700px) {
                width: 20rem;
             }
            //  @media (max-width: 485px) {
            //     width: 30rem;
            //  }
        }
    }

    span {
        @media (max-width: 700px) {
            width: 100%
         }
    }
    p {
        @media (max-width: 700px) {
           width: 100%
        }
    }
}

.condition-list-wrapper {
    margin-left: 1.5rem;
    color: #555
}
.condition-list {
    margin-top: 1.5rem;
}
// Terms Acceptance Slider
.terms-acceptance-slider-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 4.5rem;
    height: 2.5rem;
    background-color: #00071F;
    border-radius: 10rem;
    overflow: hidden;
}
.terms-acceptance-slider-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 4.5rem;
    height: 2.5rem;
    background-color: #00071F;
    transition: all .2s;
}
.terms-acceptance-slider {
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    background-color: #8095E0;
    margin-left: .5rem;
    z-index: 2;
    transition: all .4s
}
.terms-acceptance-checkbox {
    position: absolute;
    width: 100%;
    height: 2.5rem;
    opacity: 0;
    z-index: 3;
}
.terms-acceptance-checkbox:checked ~ .terms-acceptance-slider {
    margin-left: 2.5rem;
    background-color: $app-color-green;
}
.terms-acceptance-checkbox:checked ~ .terms-acceptance-slider-shadow {
    background-color: #fff;
}

.loan-acceptance-action {
    @media (max-width: 560px) {
        display: flex !important;
        flex-direction: column !important; 
        justify-content: flex-start;
        align-items: center
    }
}

.offer-acceptance-action {
    @media (max-width: 400px) {
        display: flex !important;
        flex-direction: column !important; 
        justify-content: flex-start;
        align-items: center
    }
}

.borderless-button, .borderless-button-plus {
    padding: .5rem 1rem;
    outline: none;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    transition: all .2s;
    color: $app-color-green;
    font-weight: 700;
    font-size: 1.4rem;

    &:hover, &:focus {
        border: none;
        color: #FED0B7;
        outline: none
    }

    @media (max-width: 560px) {
        margin-bottom: 3.5rem
    }

    .borderless-button-icon {
        font-size: 1.8rem;
        margin-right: .8rem;
        
    }
}
.borderless-button-plus {
    @media (max-width: 560px) {
        margin-bottom: 0
    }

    @media (max-width: 400px) {
        margin-bottom: 3rem
    }

}

.underlined-link-btn {
    text-decoration: underline;
    outline: none;
    color: $app-color-green;
    font-weight: 700;
    transition: all .2s;
    font-size: 1.4rem;
    cursor: pointer;

    &:hover, &:visited {
        outline: none;
        color: $app-color-tea
    }
}

.loan-acceptance-btns {
    @media (max-width: 350px) {
        display: flex !important;
        flex-direction: column !important; 
        justify-content: flex-start;
        align-items: center
    }

    button {
        @media (max-width: 350px) {
            margin-left: 0;
            margin-top: 2rem
        }
    }
}

.popup-sm-side-btn {
    @media (max-width: 370px) {
        display: flex !important;
        flex-direction: column !important; 
        justify-content: flex-start;
        align-items: center;
        margin-top: 3rem !important;
    }

    button {
        @media (max-width: 370px) {
            margin-left: 0;
            margin-top: 2rem
        }
    }
}

.signature-box {
    width: 42rem;
    height: 20rem;
    margin-top: 5rem;
    border-radius: 1.5rem;
    border: 2.5px solid #2E4DBD;
    position: relative;

    @media (max-width: 402px) {
        width: 90%
    }
    @media (max-width: 320px) {
        width: 97%
    }
}
.signature-shadow-y {
    position: absolute;
    width: 75%;
    height: 23rem;
    top: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: #081952;
}
.signature-shadow-x {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45rem;
    height: 75%;
    top: 50%;
    left: -1.5rem;
    transform: translateY(-50%);
    background-color: #081952;

    @media (max-width: 402px) {
        width: 110%;
        padding: 0 1.5rem;
    }
}

.signature-image {
    max-width: 100%;
    max-height: 100%;
    @media (max-width: 400px) {
        transform: scale(.65) !important;
    }
}

.no-notification {
    width: 100%;
    min-height: 60%;
    @include flex-column;
    justify-content: center;
    align-items: center
}

.img-circle-wrapper {
    width: 6.4rem;
    height: 6.4rem;
    border-radius: 50%;
    background-color: #fff;
    position: relative;

    img {
        min-width: 100%;
        max-width: 100%
    }
}

.img-circle-icon {
    position: absolute;
    bottom: .2rem;
    right: .35rem;
    background-color: #0d9aff;
    @include flex-column;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    color: #fff
}
.check-circle-icon {
    font-size: 1.4rem;
}