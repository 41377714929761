@import 'src/index.scss';

.loan-details-content {
    width: 100%;
    @include flex-row;
    position: relative;

    @media (max-width: 1050px) {
        @include flex-column;
        align-items: center;
    }
}
.loan-details-content-1 {
    width: 60%;
    margin-right: 5%;

    @media (max-width: 1050px) {
        width: 80%;
        margin-right: 0;
    }
    @media (max-width: 550px) {
        width: 95%
    }
}
.loan-details-content-2 {
    width: 30%;
    position: fixed;
    padding-right: 3rem;
    padding-top: 13rem;
    top: 0;
    right: 0;
    height: 100vh;

    @media (max-width: 1050px) {
        position: relative;
        padding-right: 0;
        padding-top: 0;
        margin-top: 10rem;
        width: 80%;
        height: auto;
    }
    @media (max-width: 550px) {
        width: 95%
    }
}

.loan-details-title {
    width: 100%;
    @include flex-row;
    align-items: flex-start;
}
.back-btn-icon {
    font-size: 3rem;
    color: $app-color-green;
    margin-right: 2.5rem;
    transition: all .2s;

    &:hover {
        color: $app-color-tea
    }
}
.compare-btn-wrapper {
    width: 100%;
    @include flex-row;
    justify-content: flex-end;

    @media (max-width: 360px) {
        justify-content: center;
        margin-bottom: 3rem;
    }
}
.compare-btn-icon {
    font-size: 2rem;
    margin-right: 1.5rem
}
.compare-link-text {
    text-decoration: underline;
    color: inherit;

    &:hover, &:link, &:visited {
        color: inherit
    }
}

.loan-details-summary, .offer-details-summary {
    background: #081952;
    border-radius: .8rem;
    width: 100%;
    padding: 1.5rem 2.5rem;
    margin-bottom: 1.5rem;
    margin-top: 5rem;
    overflow: hidden;
    position: relative;
}
.offer-details-summary {
    border: 1px solid #C1CDF6;
    overflow: visible;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.selected-offer-wrapper {
    width: 95%;
    margin: 1rem auto;

    &:not(:first-child) {
        margin-top: 3rem
    }
}
.selected-data-container {
    @include flex-row;
    flex-wrap: wrap;

    // @media (max-width: 1190px) {
    //     justify-content: space-between
    // }

    div {
        margin-bottom: 2rem;
        &:not(:last-child) {
            margin-right: 3rem
        }
    }
}
.details-data-container {
    @include flex-row;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media (max-width: 1190px) {
        justify-content: space-between
    }

    div {
        width: 12rem; // 16
        margin: 0 1rem 5rem 1rem;
        //margin-bottom: 5rem;
    }
}
.down-line-abs {
    width: 100%;
    height: 1.1rem;
    background: #4700E0;
    position: absolute;
    bottom: 0;
    left: 0
}
.about-loan {
    margin-top: 4rem;
    width: 100%;
}
.about-loan-title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 2rem;
}
.about-loan-text {
    font-size: 1.4rem;
    font-weight:400
}

.loan-decision-btns {
    @include flex-row;
    width: 100%;
    margin-top: 5rem;
    padding-left: 2rem;

    @media (max-width: 430px) {
        flex-direction: column;
        padding-left: 0;
    }
}
.option-2-btns {
    @include flex-row;
    width: 100%;
    margin-top: 8rem;
    padding-left: 2rem;
    padding-bottom: 3rem;

    @media (max-width: 430px) {
        justify-content: space-between;
        padding-left: 0;
    }
}

.negotiate-loan-btn {
    text-decoration: underline;
    outline: none;
    color: $app-color-green;
    padding-right: 3rem;
    font-weight: 700;
    transition: all .2s;
    cursor: pointer;

    @media (max-width: 430px) {
        margin-bottom: 2rem;
        padding-right: 0
    }

    &:hover, &:visited {
        outline: none;
        color: $app-color-tea
    }
}

.personal-info-link {
    @media (max-width: 430px) {
        margin-right: 0 !important;
        margin-left: 0 !important
    }
}

.similar-loan {
    width: 100%;
    margin-top: 5rem;

    @media (max-width: 1050px) {
        margin-top: 2rem;
    }
}
.similar-loan-label {
    margin-bottom: 1.5rem;
    font-weight: 700;
    font-size: 1.8rem;
}

.loan-details-summary-similar {
    background: #081952;
    border-radius: 8px; 
    width: 100%;
    padding: 1.5rem 2.5rem;
    margin-bottom: 1.5rem;
} 

// ======== LOAN TO COMPARE =========
.loans-to-compare, .selected-offer-list {
    width: 100%;
    max-height: 65rem;
    margin-top: 5rem;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    transition: all .2s;
    @include flex-row;
    align-items: flex-start;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
        display: none
    }

    @media (max-width: 460px) {
        justify-content: center;
    }
}
.selected-offer-list {
    max-height: 56rem;
}


.loans-to-compare-info {
    border-radius: 8px;
    width: 49.5%;
    padding: 1.5rem 2.5rem;
    margin-bottom: 1.2rem;
    margin-right: 1%;
    background: $app-background;
    transition: all .2s;

    &:nth-child(even) {
        margin-right: 0
    }

    @media (max-width: 460px) {
        width: 85%
    }

    @media (max-width: 320px) {
        width: 95%
    }
} 

.agreement-info-wrapper {
    width: 100% !important;
    max-height: 50rem !important;
    overflow-y: auto !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    transition: all .2s !important;

    &::-webkit-scrollbar {
        display: none !important
    }

    @media (max-width: 560px) {
        max-height: 60rem !important;
    }

    @media (max-width: 400px) {
        max-height: 57rem !important;
    }
}

.agreement-terms-btn {
    @media (max-width: 400px) {
        margin-bottom: 1rem !important
    }
}

.agreement-issue-offer-btn {
    button {
        @media (max-width: 400px) {
            margin-left: 1rem !important
        }
        @media (max-width: 350px) {
            margin-top: 0rem !important
        }
    }
}

// ====== COMPARE LOAN ==============

.loan-compare-header, .portfolios-header {
    @include flex-row;
    align-items: flex-start;
    width: 100%;
    margin-top: 4rem;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none
    }

    // @media (max-width: 500px) {
    //     @include flex-column;
    // }
}
.portfolios-header {
    display: block;
}
.loan-compare-info {
    width: 31rem; //49%
    margin: 0 1.5rem;
    min-height: 55rem;
    border-radius: 8px;
    padding: 1.5rem 2rem;
    background: $app-background;
    transition: all .2s;
    display: inline-block

    // @media (max-width: 500px) {
    //     width: 80%;
    //     margin-right: 0
    // }

    // @media (max-width: 390px) {
    //     width: 95%
    // }
}
.loan-peck-container {
    width: 12rem;
    margin: 0 2rem;
    display: inline-block
}
.loan-peck-title {
    font-size: 1.6rem;
    font-weight: 700;
    display: block;
    @include zero-space
}

.loan-peck {
    @include flex-row;
    width: 100%;
    margin: 2rem 0 3rem 0;

    label {
        width: 20rem;
        margin-right: 1.6rem
    }
    span {
        width: 20rem;
        margin-right: 1.6rem;
        font-weight: bold;

        &:last-child {
            margin-right: 0
        }
    }
}
.wrapper-flex-row-end {
    width: 100%;
    @include flex-row;
    justify-content: flex-end;
    margin-top: 2rem;
}
.loan-peck-btns-wrapper {
    width: 45rem;

    @media (max-width: 465px) {
        width: 100%
    }
}

.loan-peck-btns {
    width: 100%;
    @include flex-row;
    justify-content: space-around;
    margin-top: 3rem;

    @media (max-width: 465px) {
        @include flex-column;
        justify-content: center;
        align-items: center;
        
        button {
            width: 26rem;
            margin-bottom: 2rem
        }
    }
}
.loan-negotiation-form {
    width: 100%;
    margin-top: 8rem;
}