@import 'src/index.scss';

.sidebar-container {
    width: 23rem;
    height: 100%;
    padding-top: 8rem;
    background-color: $app-background;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    transition: all .2s;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none
    }

    @media (max-width: 770px) {
        left: -23rem;
    }
}

.sidebar-menu-wrapper {
    width: 18rem;
    margin: 4rem 2.5rem 2rem 2.5rem;
}
.sidebar-menu {
    width: 100%;
    @include flex-row;
    padding: 0 1.5rem;
    height: 4rem;
    margin-bottom: 1rem; // 1.8
    background-color: none;
    color: #4C5B8F !important;
    border-radius: 4px;
    transition: all 0.2;
    text-decoration: none;
    outline: none;

    &:hover {
        background-color: rgba(#6DE7B4, .04);
        text-decoration: none;
    }
}
.selected-menu {
    background-color: rgba(#6DE7B4, .15) !important;
    color: #6DE7B4 !important;

    &:hover {
        background-color: rgba(#6DE7B4, .15);
        text-decoration: none;
    }
}
.sidebar-menu-icon {
    font-size: 2rem;
    margin-right: 1.5rem;
}
.sidebar-menu-title {
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.logout-btn {
    width: 18rem;
    background-color: #081952;
    height: 6rem;
    @include flex-row;
    border-radius: .8rem;
    text-decoration: none;
    outline: none;
    padding-left: 1.5rem;
    margin-top: 5rem;
    margin-bottom: 3rem;
    margin-left: 2.5rem;
    // position: absolute;
    // bottom: 3rem;
    // left: 2.5rem;
    transition: all .2s;

    &:hover, &:visited {
        text-decoration: none;
        outline: none;
        background-color: $app-background-light;
        border: 1px solid #FED0B7
    }
}
.logout-icon {
    color: #6DE7B4;
    font-size: 3rem;
    margin-right: 2rem;
}
.logout-text {
    padding: 0;
    margin: 0;
    font-weight: 700;
    cursor: pointer;
    color: #fff
}
