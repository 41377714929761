@import 'src/index.scss';

.loan-type-container {
    width: 100%;
    @include flex-row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem 3rem;

    @media (max-width: 1000px) {
        padding: 2rem 1.5rem;
    }

    @media (max-width: 970px) {
        @include flex-column;
        align-items: center;
        justify-content: center

    }
}
.loan-type-title {
    width: 40%;
    margin-right: 13%;

    @media (max-width: 970px) {
        width: 80%;
        margin-right: 0;
        margin-bottom: 5rem;
    }

    @media (max-width: 500px) {
        width: 90%;
    }

    @media (max-width: 350px) {
        width: 95%;
    }
}

.loan-type-title-flex {
    width: 100%;
    @include flex-row;
    align-items: flex-start;

    .loan-back-icon {
        font-size: 5rem;
        color: $app-color-green;
        margin-right: 2.5rem;
        transition: all .2s;

        &:hover {
            color: $app-color-tea
        }
    }
}

.loan-type-title-content {
    
    p {
        font-size: 2.2rem;
        font-weight: 700;
    }
    span {
        font-size: 1.4rem;
    }
}

.loan-type-content {
    width: 47%;

    @media (max-width: 970px) {
        width: 70%;
    }

    @media (max-width: 500px) {
        width: 90%;
    }

    @media (max-width: 350px) {
        width: 95%;
    }
}

.input-with-icon {
    border: 1px solid #fff;
    border-radius: .8rem;
    background-color: transparent;
    //padding: 0 1.5rem;
    color: #fff;
    height: 5rem;
    width: 100%;
    position: relative;
    
    input, select {
        height: 5rem;
        border: none;
        color: #fff;
        background: transparent;
        width: 100%;
        padding: 0 1.5rem; // new
        outline: none;
    }

    select {
        -webkit-appearance: none;
        appearance: none;
        //new
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        option {
            color: rgba(#000, .8)
        }
    }

    .amount-input {
        padding-left: 3rem;
    }

    .input-with-icon-tag, span, .amount-symbol{
        position: absolute;
        top: 50%;
        right: 2rem;
        transform: translateY(-50%);
        font-size: 1.7rem;
    }

    span {
        font-size: 1.3rem;
        font-weight: 400
    }

    .amount-symbol {
        width: 2.5rem;
        left: 1.5rem;
        font-size: 1.7rem;
    }
}
.loan-creation-wrapper {
    width: 100%;
    @include flex-row;
    justify-content: space-between;
    margin-top: 10rem;

    span {
        transition: all .2s;
        cursor: pointer;

        &:hover {
            color: #FED0B7
        }
    }
}
.title-edit-text {
    text-decoration: underline;
    outline: none;
    color: $app-color-green;
    font-weight: 700;
    transition: all .2s;
    font-size: 1.4rem;
    cursor: pointer;

    &:hover, &:visited {
        outline: none;
        color: $app-color-tea
    }
}
