@import 'src/index.scss';

.right-align-text {
    text-align: right;
    color: #fff;
}
.registration-link {
    color: #6DE7B4;
    text-decoration: underline;
    outline: none;
    cursor: pointer;
    transition: all .2s;

    &:hover, &:visited {
        outline: none;
    }

    &:hover {
        color: $app-color-tea
    }
}

.main-content-wrapper {
    margin: 10rem 7rem 2rem 7rem;
    width: 40rem;

    @media (max-width: 950px) {
        margin: 10rem 2rem 2rem 2rem;
    }

    @media (max-width: 750px) {
        margin: 10rem auto 2rem auto;
    }

    @media (max-width: 370px) {
        width: 100%
    }

    h3 {
        font-size: 3rem;
        font-weight: 700;
    }
    p {
        color: #D5D5D5;
    }
}

.login-form {
    width: 100%;
    margin-top: 5rem;

    @media (max-width: 600px) {
        @include flex-column;
        align-items: center;
    }
}
.form-group-wrapper {
    width: 100%;
    margin-bottom: 3.5rem;
}
.form-label {
    display: block;
    padding-bottom: 1rem;
}
.form-input-field {
    border: 1px solid #fff;
    border-radius: .8rem;
    background-color: transparent;
    padding: 0 1.5rem;
    color: #fff;
    outline: none;
    height: 5rem;
    width: 100%;

    &::placeholder {
        color: #6B7597
    }
}
.passcode-input-wrapper {
    width: 100%;
    @include flex-row;
}
.passcode-input {
    border: 1px solid #fff;
    border-radius: .8rem;
    background-color: transparent;
    color: #fff;
    outline: none;
    height: 5rem;
    width: 5rem;
    margin-right: 0.8rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    display: grid;
    place-items: center;

    @media (max-width: 370px) {
        width: 15.42%;
        margin-right: 1.5%
    }

    &:last-child {
        margin-right: 0
    }
}
.login-btn {
    width: 20.2rem;
    height: 5rem;
    background: #6DE7B4;
    border-radius: .8rem;
    outline: none;
    color: $app-background;
    font-weight: 700;
    margin-top: 10rem;
    transition: all .2s;

    &:hover, &:focus {
        background-color: #FFC49D
    }
}
.form-btn-wrapper {
    width: 100%;
    margin-left: 7rem;
    @include flex-row;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: -12rem;
    margin-bottom: 3rem;

    @media (max-width: 1280px) {
        margin-top: 4rem;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 40rem;
    }

    @media (max-width: 950px) {
        margin: 4rem 2rem 3rem 2rem;
    }

    @media (max-width: 750px) {
        margin: 4rem auto 3rem auto;
    }

    @media (max-width: 370px) {
        width: 100%
    }
}
.keypad-container {
    width: 29rem;
    z-index: 2;

    @media (max-width: 1280px) {
        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto
    }
}

.keypad, .refresh-btn, .delete-btn, .clear-btn {
    width: 5.6rem;
    height: 5.6rem;
    margin-bottom: 1.2rem;
    background: #2E4DBD;
    border: 1px solid rgba(#fff, 0.3);
    border-radius: .8rem;
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
    outline: none;
    transition: all .2s;
    
    &:not(last-child) {
        margin-right: 1.5rem;
    }

    &:hover, &:focus {
        outline: none
    }
}

.refresh-btn {
    background: transparent;
    border: 1px solid #FED0B7;
    color: #FED0B7;

    &:hover {
        background: #FED0B7;
        color: #00071F
    }
}

.delete-btn {
    width: 12.7rem;
    height: 4.5rem;
    background: #FF0062;
    font-size: 1.4rem;
    margin-bottom: 0;

    &:hover {
        background: #00071F;
        color: #fff
    }
}
.clear-btn {
    width: 12.7rem;
    height: 4.5rem;
    background: transparent;
    border: 1px solid #FF0062;
    font-size: 1.4rem;
    margin-bottom: 0;
}

.signin-btn {

    @media (max-width: 1280px) {
        width: 29rem !important;
        margin: 2rem auto !important;
        margin-bottom: 5rem !important;
    }
}

.otp-resend-btn {
    &:hover {
        border: 1px solid #FED0B7;
        
        span, a {
            color: #FED0B7;
        }
    }
}