@import 'src/index.scss';

.loan-offers-container {
    width: 100%;
    padding: 3rem 2rem;

    @media (max-width: 430px) {
        padding: 3rem 0;
    }
}
.loan-offer-title-wrapper {
    width: 100%;
    @include flex-row;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 1135px) {
        @include flex-column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    // @media (max-width: 700px) {
    //     @include flex-column;
    //     justify-content: flex-start;
    //     align-items: flex-start;
    // }
}
.loan-offers-title {
    width: 40%;
    @include flex-row;
    align-items: flex-start;

    @media (max-width: 1135px) {
        width: 70%;
        margin-bottom: 3.5rem;
    }
    // @media (max-width: 700px) {
    //     width: 70%;
    //     margin-bottom: 3.5rem;
    // }
    @media (max-width: 500px) {
        width: 85%;
    }
    @media (max-width: 360px) {
        width: 95%;
    }

    .loan-back-icon {
        font-size: 3rem;
        color: $app-color-green;
        margin-right: 2.5rem;
        transition: all .2s;

        &:hover {
            color: $app-color-tea
        }
    }
}

.search-for-offers {
    width: 45rem;
    @include flex-row;

    @media (max-width: 1135px) {
        //margin-top: 3rem;
        align-self: flex-end;
    }

    // @media (max-width: 970px) {
    //     width: 50%
    // }
    // @media (max-width: 700px) {
    //     width: 35rem;
    //     align-self: flex-end;
    // }
    @media (max-width: 500px) {
        width: 100%;
        align-self: center;
    }
    @media (max-width: 380px) {
        flex-wrap: wrap;
        justify-content: flex-end;
    }
}

.search-input-wrapper, .search-transaction-wrapper {
    width: 50%; // 80
    height: 4rem;
    border: 1px solid #4B5D99;
    border-radius: 4px;
    position: relative;
    padding: 0 1.5rem 0 5rem;

    .search-icon-center {
        position: absolute;
        top: 50%;
        left: 1rem;
        color: #818181;
        transform: translateY(-50%);
        font-size: 2rem;
        transition: all .2s;

        &:hover {
            color: $app-color-tea !important
        }
    }
}
.search-offer-input {
    font-size: 1.4rem;
    width: 100%;
    height: 4rem;
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff
}
.flex-item-icon {
    margin-left: 0.5rem;
    font-size: 2.5rem
}

.sort-icon {
    margin: 0.9rem 0.5rem;
    font-size: 2rem;
}
.sort-by-text {
    padding: 0.9rem 2rem !important;
    font-weight: 400 !important;
    border-right: 1px solid #4B5D99 !important;
    cursor: pointer !important;
}
.filter-text-icon, .trans-filter-icon {
    font-size: 1.3rem !important;
    font-weight: 400 !important;
    margin-left: 0.8rem !important;
    padding: 0.6rem 0.5rem !important;
    cursor:  pointer !important;
    border: 1px solid #4B5D99 !important;
    border-radius: 0.4rem !important;

    @media (max-width: 380px) {
        margin-top: 1.5rem !important;
        // text-align: left !important;
    }
}

.filter-quick-access {
    @media (max-width: 380px) {
        margin-top: 0 !important;
        // text-align: left !important;
    }
}
.sort-select-option {
    border-bottom: 1px solid rgba(128, 149, 224, 0.2) !important;
    padding: 0.5rem 1rem !important;
    justify-content: space-between !important;
    transition: all .2s;

    &:hover {
        background-color: #00071F;
    }
}

.loan-offers-content {
    width: 100%;
    @include flex-row;
    flex-wrap: wrap;
    margin-top: 5rem;

    @media (max-width: 545px) {
        justify-content: center
    }
}
.loan-offers-to-select {
    position: relative;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-height: 33rem;
    padding-top: 3rem;
    margin-top: 0;

    &::-webkit-scrollbar {
        display: none
    }
}
.loan-offer-selected {
    background: #122C85 !important;
    border: 1px solid #C1CDF6;
}
.loan-selected-notifier-box {
    padding: 0.8rem 1.8rem 1.8rem 1.8rem !important;
    width: 52rem !important;
    background-color: #081952 !important;
    margin-right: 4rem !important;
    border-radius: .4rem !important;
    box-shadow: 0px 0px 80px 10px rgba(0, 0, 0, 0.8);

    @media (max-width: 500px) {
        width: 94% !important;
        margin-left: 3% !important;
        margin-right: 3% !important;
    }
}
.loan-offer-info, .loan-offer-selected-none {
    position: relative;
    background: #081952;
    border-radius: 8px;
    width: 32%;
    max-width: 40rem;
    padding: 1.5rem;
    padding-bottom: 0.5rem;
    margin-right: 1.2%;
    margin-bottom: 1.5rem;

    @media (max-width: 1120px) {
        width: 45%
    }

    @media (max-width: 922px) {
        width: 48%
    }

    @media (max-width: 545px) {
        max-width: none;
        width: 90%
    }

    @media (max-width: 350px) {
        width: 100%
    }

    &:last-child {
        margin-right: 0
    }
}

.loan-offer-selected-none {
    background: none;
    min-height: 21rem;
    border: 1px dashed #C1CDF6;
    display: grid;
    place-items: center;
}

.loan-offer-header {
    width: 100%;
    @include flex-row;
    justify-content: space-between;
    align-items: center // flex-start
}
.loan-offer-label {
    @include flex-row;
}
.loan-offer-header-icon {
    margin-right: 1.5rem;
}
.loan-offer-label-text {
    font-size: 1.25rem;
    text-align: left;

    label {
        color: #E2E2E2;
        @include zero-space;
    }
    p {
        font-size: 1.4rem; // 1.35
        font-weight: 700;
        @include zero-space;
    }
}
.loan-offer-type-text {
    font-size: 1.6rem !important;
}
.loan-offer-percentage {
    font-size: 1.8rem;
    font-weight: 700
}

.margin-vertical-1-5 {
    margin: 1.5rem 0;
}
.width-10-rem {
    width: 10rem
}
.flex-end {
    align-self: flex-end;
}

.loan-filter-form {
    width: 100%;
    margin-top: 5rem;
}

.no-offer-container {
    width: 100%;
    margin-top: 7rem;
    display: grid;
    place-items: center;

    p {
        text-align: center;
    }
}

.filter-entry {
    //margin-right: 4rem !important;
    &:nth-child(odd) {
        width: 17rem !important
    }
}