@import 'src/index.scss';

.info-text-wrapper {
    position: absolute;
    top: 50%;
    left: 5rem;
    width: 100%;
    transform: translateY(-50%);
}

.info-text-title {
    font-size: 3rem;
    width: 24rem;
    color: #fff;
    word-wrap: break;

    span {
        font-weight: bold;
        color: #FFC49D
    }
}
.info-text, .info-text-plus {
    font-size: 1.5rem;
    color: #D5D5D5;
    width: 21rem;
    padding-bottom: 2rem;
    border-bottom: 2px solid #fff;
}
.info-text-plus {
    width: 25rem;
}
.app-logo {
    position: fixed;
    top: 4rem;
    left: 5rem;
    z-index: 3;

    @media (max-width: 750px) {
        transform: scale(.7);
        top: 1.5rem;
        left: 2rem
    }
}

.bottom-bubble {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.subcontainer-children {
    position: relative;
    z-index: 2 !important
}

.sidebar-card {
    width: 27rem;
    border-radius: .8rem;
    height: 14rem;
    background-color: #fff;
    position: absolute;
    bottom: 3rem;
    right: 3rem;
}
.sidebar-card-wrapper {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    position: relative
}
.sidebar-card-logo {
    position: absolute;
    bottom: 0;
    right: 0
}
.onboarding-progress-text {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}