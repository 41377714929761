@import 'src/index.scss';

.vnin-container {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    background-color: #081952 !important;
    transform: translate(-50%, -50%) !important;
    padding: 2.5rem !important;
    width: 50rem !important;
    min-height: 55rem !important;
    max-height: 90% !important;
    z-index: 8 !important;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03) !important;
    border-radius: 2rem !important;
    overflow-y: auto;
    overflow: auto !important;
    // white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none
    }

    @media (max-width: 420px) {
        width: 93% !important;
    }
}

.vnin-popup-container {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    background-color: #081952 !important;
    transform: translate(-50%, -50%) !important;
    padding: 2.5rem !important;
    width: 45rem !important;
    min-height: 55rem !important;
    max-height: 90% !important;
    z-index: 8 !important;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03) !important;
    border-radius: 2rem !important;
    overflow-y: auto;
    overflow: auto !important;
    // white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none
    }

    @media (max-width: 420px) {
        width: 93% !important;
    }
}

.dial-code-container {
    &:hover {
        span {
            color: #6DE7B4;
        }
        div {
            background-color: #6DE7B4;
        }
    }

    @media (max-width: 310px) {
        span {
            font-size: 1.5rem !important;
        }
    }
}

.nin-img {
    width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
    max-width: 100% !important;
}