@import 'src/index.scss';

.community-latest-full {
    height: 37.4rem;
    width: 100%;
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.7) 0%, rgba(0, 7, 31, 0.2) 100%), url("../../../assets/img/latest-community-img.png");
    background-size: cover;
    background-position: top;
    border-radius: .8rem;
    padding: 1.2rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;

    @media (max-width: 581px) {
        height: 25rem;   
        background-position: center; 
    }
}

.community_text-wrapper-full {
    width: 100%;
    position: absolute;
    top: 7rem;
    left: 4rem;

    @media (max-width: 581px) {
        top: 2rem;
        left: 2rem
    }

    p {
        width: 30rem;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 3rem;
    }
}
.btn-expand-width {
    width: 15rem !important;
}

.explore-img-card {
    height: 32rem;
    width: 31rem;
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.7) 0%, rgba(0, 7, 31, 0.2) 100%), url("../../../assets/img/latest-community-img.png");
    background-size: cover;
    background-position: top;
    border-radius: .8rem;
    padding: 1.2rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    margin-bottom: 2rem;
    margin-right: 2rem;

    @media (max-width: 1053px) {
        width: 30rem;
        height: 32rem
    }

    @media (max-width: 991px) {
        width: 40rem;
    }

    @media (max-width: 751px) {
        width: 32rem;
        height: 33rem
    }
    @media (max-width: 581px) {
        width: 100%;
        height: 25rem;
        margin-right: 0;
        background-position: center;
    }
}

.explore-community1 {
    background-image: url("../../../assets/img/community-1.png") !important;
}
.explore-community2 {
    background-image: url("../../../assets/img/community-2.png");
}
.explore-community3 {
    background-image: url("../../../assets/img/community-3.png");
}
.explore-community4 {
    background-image: url("../../../assets/img/community-6.png") !important;
}
.explore-community5 {
    background-image: url("../../../assets/img/community-5.png");
}
.explore-community6 {
    background-image: url("../../../assets/img/community-4.png");
}
.explore-community7 {
    background-image: linear-gradient(rgba(#000, .6), rgba(#000, .6)), url("../../../assets/img/community-7.png");
}
.explore-community8 {
    background-image: linear-gradient(rgba(#000, .6), rgba(#000, .5)), url("../../../assets/img/community-8.png");
}
.explore-community9 {
    background-image: linear-gradient(rgba(#000, .6), rgba(#000, .5)), url("../../../assets/img/community-9.png");
}