$color-white: #ffffff;
$app-background: #00071F;
$app-background-light: #000926;
$app-color-green: #6DE7B4;
$app-color-tea: #FED0B7;


@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } //600px
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    } //900px
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    } //1200px
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } //1800px
  }
}

@mixin zero-space {
  margin: 0;
  padding: 0;
}
@mixin side-space {
  margin: 0 1.4rem;
}
@mixin flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@mixin flex-column {
  display: flex;
  flex-direction: column;
}
@mixin flex-row-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
}


@keyframes pulsation {
  from {
    transform: scale(.9)
  }
  to {
    transform: scale(1.3)
  }
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html, body {
  font-size: 62.5%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  @include respond(tab-port) {
    font-size: 53%;
  }

  @include respond(phone) {
    font-size: 50%; // 1rem = 8px, 8/16 * 100 = 50%
  }
}
  
input,
textarea,
button,
select,
a,
label,
span,
div,
p{
    -webkit-tap-highlight-color: rgba(0,0,0,0)
}


body {
  margin: 0;
  // font-family: benton-sans,'Helvetica Neue',helvetica,arial,sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 1.5rem;
  font-style: normal;
  color: #fff;
  background-color: $app-background-light;

  overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    z-index: 4;

    &::-webkit-scrollbar {
        display: none
    }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.flex-row-vertical-center {
  @include flex-row;
}
.flex-row-vertical-start {
  @include flex-row;
  align-items: flex-start
}

.flex-row-space-between {
  width: 100%;
  @include flex-row;
  justify-content: space-between;
}
.flex-row-space-between-wrap {
  width: 100%;
  @include flex-row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex-end-container {
  width: 100%;
  @include flex-row;
  justify-content: flex-end;
}
.loan-action-btn:disabled {
  background-color: #2b2b2b !important;
  border: none !important;
}

.acct-action-btn:disabled {
  background-color: #2b2b2b !important;
  border: none !important;
  color: #00071F !important;

  &:hover {
    color: #00071F !important;
  }
}

.loan-action-outline-btn:disabled {
  color: #2b2b2b !important;
  border-color: #2b2b2b !important;

  &:hover {
    background-color: transparent !important;
    color: #2b2b2b !important;
    border: 1px solid#2b2b2b !important;
  }
}

.disable-a-tag {
  color: #2b2b2b;

  &:hover {
    color: #2b2b2b;
  }
}

.display-hidden {
  position: absolute;
  font-size: 0.5rem;
  width: 4rem;
  height: 0;
  opacity: 0;
}

.display-hidden:focus ~ .active-pin-box {
  //box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
  border-width: 3px;
}

.icon-component {
  @media (max-width: 900px) {
    transform: scale(0.9);
  }
  @media (max-width: 600px) {
    transform: scale(0.8);
  }
}