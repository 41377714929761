@import 'src/index.scss';

.loan-info-kind-toggle, .browse-type-toggle {
    width: 20rem;
    height: 3.4rem;
    background-color: $app-background;
    @include flex-row;
    justify-content: space-between;
    position: relative;
    border-radius: 10rem;
    overflow: hidden;

    span {
        font-size: 1.3rem;
        width: 49%;
        height: 100%;
        display: grid;
        place-items: center;
        text-align: center;
        cursor: pointer;
        z-index: 2;
    }
}
.loan-info-kind-shadow, .browse-type-toggle-shadow {
    width: 49%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2E4DBD;
    border-radius: 10rem;
    transition: all .2s;
}

.browse-type-toggle {
    width: 18rem;
    height: 3.5rem;
    margin-left: 2rem;
    background-color: #2E4DBD;
}

.browse-type-toggle-shadow {
    border: 2px solid #2E4DBD;
    background-color: #8095E0;
}

.center-flex-item {
    @media (max-width: 520px) {
        justify-content: center !important;
    }
}
.center-flex-with-column {
    @media (max-width: 520px) {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        justify-content: center !important;
    }
}
.bottom-margin-center {
    @media (max-width: 520px) {
        margin-bottom: 3rem !important
    }
}

.center-flex-with-column-350px {
    @media (max-width: 350px) {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        justify-content: center !important;
    }

    button {
        &:first-child {
            @media (max-width: 350px) {
                margin-bottom: 1.5rem;
                margin-right: 0 !important
            }
        }
    }
}
.toggle-loan-types {
    width: 100%;
    height: 5.5rem;
    border-bottom: 1px solid #2E4DBD;
    @include flex-row;
    justify-content: flex-start;
    position: relative;

    span {
        font-size: 1.3rem;
        width: 17rem;
        height: 100%;
        padding-left: 1rem;
        color: #676767;
        display: grid;
        place-items: center;
        text-align: center;
        cursor: pointer;
        z-index: 2;

        @media (max-width: 477px) {
            width: 33.3%
        }
    }
}
.loan-type-toggler {
    width: 17rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 4px solid #FED0B7;
    transition: all .2s;

    @media (max-width: 477px) {
        width: 33.3%
    }
}
.custom-loanType-1 {
    color: #fff !important;
    font-weight: 700
}
.custom-loanType-toggler-1 {
    left: 0;
}
.custom-loanType-toggler-2 {
    left: 17rem;

    @media (max-width: 477px) {
        left: 33.3%
    }
}
.custom-loanType-toggler-3 {
    left: 34rem;

    @media (max-width: 477px) {
        left: 66.6%
    }
}

.loan-info-card {
    min-height: 17.4rem;
    width: 34.7rem;
    padding: .5rem 1.5rem;
    background-color: #081952;
    overflow: hidden;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;
    border-radius: .8rem;

    @media (max-width: 1032px) {
        width: 40rem
    }
    @media (max-width: 900px) {
        width: 34.7rem
    }
    @media (max-width: 878px) {
        width: 45rem
    }
    @media (max-width: 770px) {
        width: 34.7rem
    }
    @media (max-width: 682px) {
        width: 45rem
    }
    @media (max-width: 450px) {
        width: 95%
    }
    @media (max-width: 370px) {
        width: 100%
    }
}
.fund-portfolio-cards, .fund-portfolio-card-plus {
    min-height: 17.4rem;
    width: 34.7rem;
    padding: .5rem 1.5rem;
    background-color: #00071F;
    overflow: hidden;
    margin-right: 1rem;
    position: relative;
    border-radius: .8rem;
    transition: all .2s;
    display: inline-block
}

.fund-portfolio-card-plus {
    background-color: #2E4DBD;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.25);
}

.info-card-wrapper {
    width: 100%;
    z-index: 2;
}
.card-bubble {
    position: absolute;
    right: -3rem;
    top: -5rem
}

.center-card-item {
    @media (max-width: 682px) {
        justify-content: center !important;
    }
}
.loan-data-rem-pay {
    margin-top: 1rem;
    padding-left: 2.5rem;
    border-left: 1px solid #A380EF;
    margin-left: 3.5rem
}
.loan-data-sum-pay {
    padding-left: 1.8rem;
    border-left: 1px solid #A380EF;
    margin-left: 1rem
}

.borrower-title-wrapper {

    @media (max-width: 970px) {
        width: 40%;
        margin-right: 0;
        margin-bottom: 5rem;
    }

    @media (max-width: 550px) {
        width: 90%;
    }

}

.compare-borrowers {
    margin-top: -4rem;

    @media (max-width: 970px) {
        margin-top: -9rem;
    }
    @media (max-width: 550px) {
        justify-content: center !important;
        margin-top: 0rem;
    }
}

.portfolio-details-horizontal {
    justify-content: flex-start !important;
    align-items: flex-start !important;
    min-height: 22rem !important;
    background-color: #081952 !important;
    margin-top: 5rem !important;
    border-radius: .8rem !important;
    overflow: hidden;

    @media (max-width: 450px) {
        flex-direction: column !important;
        min-height: 30rem !important;
    }
}
.portfolio-borrower-count {
    width: 28rem !important;
    height: 23rem !important;
    background-color: #4700E0 !important;

    @media (max-width: 924px) {
        height: 28.5rem !important;
    }

    @media (max-width: 450px) {
        flex-direction: column !important;
        height: 12rem !important;
        width: 100% !important;
    }
}
.portfolio-info-horizontal {
    width: 100% !important;
    padding: 0 2rem 1.5rem 2rem !important
}
.portfolio-details-container {
    @include flex-row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 3rem;

    div {
        width: 12rem;
        margin: 0 1rem 2rem 1rem;
        
        label {
            font-size: 1.1rem;
        }
    }
}
.borrowers-wrapper {
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    margin-top: 1rem !important;
    transition: all .2s !important;

    @media (max-width: 430px) {
        //justify-content: center !important;
    }
}
.interested-borrowers, .borrower-selected-none {
    min-width: 24rem;
    background-color: #081952;
    border-radius: 4px;
    padding: 1rem 1.5rem;
    min-height: 10rem;
    margin-right: 1.1rem;
    margin-bottom: 1.1rem;
}

.borrowers-wrapper-compare {
    max-height: 70rem !important;
    margin-top: 3rem !important;
    overflow-y: auto !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    transition: all .2s !important;

    &::-webkit-scrollbar {
        display: none
    }
}

.interested-borrowers-plus {
    background-color: #00071F;
    transition: all .2s;

    &:hover {
        background-color: #2E4DBD;
    }
}

.compare-borrowers-popup {
    @media (max-width: 740px) {
        width: 100% !important
    }
}

.compare-borrowers-title {
    @media (max-width: 460px) {
        align-self: flex-start !important;
    }
}
.compare-borrowers-title-wrapper {
    @media (max-width: 460px) {
        flex-direction: column !important;
    }
}
.start-borrowers-comparison {
    @media (max-width: 460px) {
        align-self: flex-end;
        margin-top: 2rem !important;
    }
}

.comparison-cancel-icon {
    position: absolute;
    top: 2rem;
    right: 2.5rem;
    color: #FED0B7;
    font-size: 1.7rem;
}

.selected-borrowers-wrapper {
    @media (max-width: 658px) {
        justify-content: center !important;
    }
}

.borrower-selected-none {
    background-color: #000926 !important;
    min-height: 14rem;
    border: 1px dashed #C1CDF6;
    display: grid;
    place-items: center;
    width: 35rem !important;

    &:first-child {
        margin-bottom: 2rem !important;
    }
}

.selected-borrower {
    min-height: 14rem;
    min-width: 35rem;
    border-radius: 4px;
    padding: 1.5rem 1.5rem;
    margin-right: 1.7rem;
    margin-bottom: 1.1rem;
    background-color: #2E4DBD;
    position: relative;

    @media (max-width: 658px) {
        
        &:first-child {
            margin-bottom: 2rem !important;
        }
    }
}