@import 'src/index.scss';

.loan-creation-wrapper-plus {
    width: 100%;
    @include flex-row;
    justify-content: flex-end;
    margin-top: 10rem;

    span {
        transition: all .2s;
        color: $app-color-green;
        margin-right: 4rem;
        cursor: pointer;

        &:hover {
            color: #FED0B7
        }
    }
}

.selected-interest-rate {
    font-size: 2.5rem;
    font-weight: bold;
    @include zero-space
}

.range-input-container {
    width: 100%;
    position: relative;
    border-radius: 10rem;
    padding: .5rem 0;
    overflow: hidden;
}

.range-input {
    -webkit-appearance: none;
    height: .7rem;
    width: 100%;
    background: #07123A;
    border-radius: 10rem;
    outline: none;
    border: none;
}
.range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 2.4rem;
    width: 2.4rem;
    background: radial-gradient(123.45% 84.23% at 90.24% 96.92%, #FD5454 0%, #FF9061 29.17%, #FFBA7B 53.33%, #FFCEC4 100%);
    border-radius: 50%;
    border: 1px solid $app-color-tea;
}

.range-progress-shadow {
    display: block;
    height: .7rem;
    position: absolute;
    border-radius: 10rem;
    top: 50%;
    left: 0;
    //transform: translateY(-40%);
    background: radial-gradient(123.45% 84.23% at 90.24% 96.92%, #FD5454 0%, #FF9061 29.17%, #FFBA7B 53.33%, #FFCEC4 100%);
}

.add-margin-plus {
    margin: 1.5rem 0;
}
.popup-title-summary {
    display: block;
    margin-top: -3rem;
    color: #E2E2E2;
    width: 35rem;
    margin-bottom: 3rem;
}