@import 'src/index.scss';

.resend-otp {
    font-size: 1.4rem;
    font-weight: 600;
    text-decoration: underline;
    color: $app-color-green;
    outline: none;
    transition: all .2s;
    cursor: pointer;

    &:hover, &:visited, &:link {
        outline: none;
        color: $app-color-tea
    }
}