@import 'src/index.scss';

.loan-permission-container {
    width: 100%
}
.loan-permission-content {
    @include flex-row;
    width: 100%;
    margin-bottom: 2.7rem;

    @media (max-width: 1230px) {
        justify-content: space-between;
    }

    .loan-permission-text {
        width: 35rem;
        font-weight: 700;

        @media (max-width: 1230px) {
            width: auto
        }
    
    }
}

.permission-slider-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 5rem;
    height: 3rem;
    background-color: #081952;
    border-radius: 10rem;
    overflow: hidden;
}
.permission-slider-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 5rem;
    height: 3rem;
    background-color: #081952;
    transition: all .2s;
}
.permission-slider {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #8095E0;
    margin-left: .4rem;
    z-index: 2;
    transition: all .4s
}
.permission-checkbox {
    position: absolute;
    width: 100%;
    height: 3rem;
    opacity: 0;
    z-index: 3;
}
.permission-checkbox:checked ~ .permission-slider {
    margin-left: 2.7rem;
    background-color: $app-color-green;
}
.permission-checkbox:checked ~ .permission-slider-shadow {
    background-color: #fff;
}

//=========== LOAN PORTFOLIO ============

.side-popup-nav-icons {
    @include flex-row;

    p {
        @include zero-space;
        margin-right: 3rem;
        color: $app-color-green;
        @include flex-row;
        align-items: flex-end;

        span {
            margin-left: .5rem;
            transition: all .2s;
            cursor: pointer;
            border-bottom: 1px solid $app-color-green;

            &:hover {
                color: $app-color-tea;
                border-bottom: 1px solid $app-color-tea;
            }
        }
    }
}
.side-bar-summary {
    width: 100% !important;
    padding: 3rem 2rem !important;
    border-radius: .8rem !important;
    background-color: #4700e0;
}
.bar-summary-data {
    width: 100%;
    @include flex-row;
    justify-content: space-between;
}
.bar-summary-data-text {
    font-size: 1.4rem;
    color: #E2E2E2;
    
    &:last-child {
        font-weight: 700;
        color: #E8E8E8
    }
}
