@import 'src/index.scss';

.email-verification-wrapper {
    min-height: 60rem !important;
    width: 60% !important;
    border-radius: .8rem !important;
    padding: 4rem 2rem !important;

    @media (max-width: 650px) {
        width: 85% !important;
    }

    @media (max-width: 360px) {
        width: 95% !important;
    }
}

.email-verification-icon {
    transform: scale(0.9);

    @media (max-width: 900px) {
        transform: scale(0.8);
    }

    @media (max-width: 600px) {
        transform: scale(0.7);
    }
}