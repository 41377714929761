@import 'src/index.scss';

.questionaire-container {
    width: 100%;
    @include flex-row;
    margin-top: 7rem;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 1140px) {
        flex-direction: column;
    }
}
.question-tabs {
    width: 35%;
    padding: 2rem 3rem;

    @media (max-width: 1140px) {
        width: 95%;
        margin: 0 auto;
        min-height: auto;
    }
    @media (max-width: 560px) {
        width: 100%
    }
}
.question-type-wrapper-inner {
    @include flex-column;
    align-items: center;
    position: relative;

    @media (max-width: 1140px) {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start
    }

    @media (max-width: 475px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center
    }
}

.question-type-wrapper {
    width: 100%;
    @include flex-column;
    align-items: center;
    position: relative;
}

.tab-link-line {
    height: 6rem;
    border: 1px dashed #2E4DBD;
    position: absolute;
    top: 3.7rem;
    left: 1rem;

    @media (max-width: 1140px) {
        display: none
    }
}

.question-tab-type {
    p, span {
        cursor: pointer
    }

    @media (max-width: 1140px) {
        margin-top: 0 !important;
    }

    &:first-child {
        @media (max-width: 1140px) {
            margin-right: 5rem
        }

        @media (max-width: 475px) {
            margin-right: 0;
            margin-bottom: 4rem
        }
    }
}
.question-data {
    width: 65%;
    border-left: 1px solid rgba(#2E4DBD, 0.2);

    @media (max-width: 1140px) {
        width: 95%;
        margin: 5rem auto 3rem;
        min-height: auto;
        border-left: none
    }
    @media (max-width: 560px) {
        width: 100%
    }
}

.question-title-wrap {
    @include flex-row;
    justify-content: space-between;
    width: 100%;
    padding: 1.5rem 2.5rem;
    border-bottom: 1px solid rgba(#2E4DBD, 0.2);

    @media (max-width: 1140px) {
        padding: 1rem .5rem;
    }
}

.question-container {
    width: 100%;
    padding: 3rem;
    
    @media (max-width: 1140px) {
        padding: 3rem .5rem;
    }
}

.question-options-wrapper {
    @include flex-row;
    width: 100%;
    margin-top: 4rem;
    flex-wrap: wrap;
}

.question-option,  .question-option-plus{
    width: 48%;
    min-height: 5.5rem;
    padding: 1.5rem 2rem;
    border: 1px solid #8095E0;
    border-radius: .4rem;
    margin-bottom: 2rem;
    cursor: pointer;

    @media (max-width: 460px) {
        width: 100%
    }

    &:nth-child(odd) {
        margin-right: 4%;

        @media (max-width: 460px) {
            margin-right: 0
        }
    }
}

.question-option-plus {
    background-color: #0930B6;
    border: none
}

.questionaire-btns {

    @media (max-width: 360px) {
        flex-direction: column !important;
        justify-content: center !important;
    }
}