@import 'src/index.scss';

.payment-card-wrapper {
    background-image: rgba(#fff, 0.7), url('../../../assets//img/logo-trans.png') !important;
    position: relative;
    background-size: cover; 
    width: 34rem;
    height: 18rem
}
.payment-abs-action-btn {
    @media (max-width: 770px) {
        top: 3rem !important
    }
    @media (max-width: 530px) {
        top: 10rem !important
    }
}

.card-main-container {
    @media (max-width: 530px) {
        margin-top: 14rem !important
    }
}