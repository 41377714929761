@import 'src/index.scss';

.explore-signup-container {
    width: 65rem;
    border: 1px solid #2E4DBD;
    border-radius: .4rem;
    padding: 2rem;
    margin-bottom: 3rem;
    @include flex-row;
    align-items: flex-end;
    justify-content: space-between;

    @media (max-width: 1270px) {
        margin: 0rem auto 3rem auto;
    }

    @media (max-width: 1072px) {
        width: 85%
    }
    @media (max-width: 875px) {
        width: 95%
    }
    @media (max-width: 580px) {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    button {
        @media (max-width: 580px) {
           margin: 2rem 0 0 0;
           align-self: flex-end
        }
    }
}
.explore-balance {
    width: 32.5%;
    height: 20rem;
    margin-top: 2rem;
    margin-right: 2%;
    padding: 2rem 1.5rem;
    background: #081952;
    border-radius: .8rem;
    position: relative;
    overflow: hidden;

    span {
        font-size: 1.4rem;
    }

    p {
        font-size: 3rem;
        font-weight: 700;
        margin-top: 1rem
    }

    @media (max-width: 1257px) {
        width: 49%
    }
    @media (max-width: 500px) {
        width: 100%;
        margin-right: 0;
    }
}

.loan-action-kind {
    justify-content: flex-start;
    width: 65.4% !important;

    @media (max-width: 1257px) {
        width: 100% !important;
    }
    @media (max-width: 500px) {
        flex-wrap: wrap !important;
    }
}

.explore-loan-option-card-1, .explore-loan-option-card-2 {
    width: 49%;
    height: 20rem;
    padding: 1.5rem;
    background: radial-gradient(123.45% 84.23% at 90.24% 96.92%, #FD5454 0%, #FF9061 29.17%, #FFBA7B 53.33%, #FFCEC4 100%);
    border-radius: .8rem;
    margin-top: 2rem;
    margin-right: 2%;

    @media (max-width: 500px) {
        width: 100%;
        margin-right: 0;
    }

    p {
        font-size: 2.2rem;
        font-weight: 700;
        color: #131176;
    }
}
.loan-option-card-text-wrapper {
    width: 100%;
    @include flex-row;
    justify-content: space-between;
    align-items: flex-start;

    p {
        font-size: 1.2rem;
        color: #070647;
        width: 80%;
        font-weight: 400;
    }

    .icon-chevron-right {
        font-size: 2rem;
        color: #070647;
        margin-right: 1.5rem;
    }
}
.explore-loan-option-card-2 {
    background: radial-gradient(107.96% 90.58% at 91.16% 100%, #63BEDB 0%, #56E77E 33.93%, #6DE7B4 52.08%, #E2FCC2 100%);
    margin-right: 0;
}

.explore-app-card {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none
    }
}
.explore-app-img-card,  .explore-app-img-card-plus{
    height: 30rem;
    width: 27rem;
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.7) 0%, rgba(0, 7, 31, 0.2) 100%), url("../../../assets/img/latest-community-img.png");
    background-size: cover;
    background-position: top;
    border-radius: .8rem;
    padding: 1.2rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    margin-bottom: 2rem;
    margin-right: 2rem;
    display: inline-block
}

.explore-community1 {
    background-image: url("../../../assets/img/community-1.png") !important;
}
.explore-community2 {
    background-image: url("../../../assets/img/community-2.png");
}
.explore-community3 {
    background-image: url("../../../assets/img/community-3.png");
}
.explore-community4 {
    background-image: url("../../../assets/img/community-6.png") !important;
}
.explore-community5 {
    background-image: url("../../../assets/img/community-5.png");
}
.explore-community6 {
    background-image: url("../../../assets/img/community-4.png");
}
.explore-community7 {
    background-image: linear-gradient(rgba(#000, .6), rgba(#000, .6)), url("../../../assets/img/community-7.png");
}
.explore-community8 {
    background-image: linear-gradient(rgba(#000, .6), rgba(#000, .5)), url("../../../assets/img/community-8.png");
}
.explore-community9 {
    background-image: linear-gradient(rgba(#000, .6), rgba(#000, .5)), url("../../../assets/img/community-9.png");
}
.explore-app-img-card-plus {
    width: 35rem;
    height: 35rem
}
.explore-scroll-hack {
    width: 40rem;
    height: 27rem;
    display: inline-block;

    @media (max-width: 1174px) {
        display: none
    }
}

.app-statistics {
    width: 41rem;
    height: 100%;
    padding: 0 3rem;
    background-color: #000926;
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: 1174px) {
        position: relative;
        margin-top: 2rem;
        width: 100%;
        padding: 0;
    }
}

// ======= Explore Loan type list ============

.loan-list-banner {
    width: 100%;
    height: 32rem;
    background: #E2E2E2;
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/community-7.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    border-radius: .8rem;
    padding: 2.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    margin: 3rem 0; 
    @include flex-column;
    justify-content: center;

    p {
        width: 45% !important;
        line-height: 3.2rem;

        @media (max-width: 580px) {
            width: 70% !important;
        }
        @media (max-width: 390px) {
            width: 95% !important;
        }
    }
    span {
        width: 45% !important;

        @media (max-width: 580px) {
            width: 70% !important;
        }
        @media (max-width: 390px) {
            width: 95% !important;
        }
    }
}

.banner-cat1 {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/community-7.png");
}
.banner-cat2 {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/community-8.png");
}
.banner-cat3 {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/cat4.png");
}
.banner-cat4 {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/community-9.png");
}
.banner-cat5 {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/cat5.png");
}
.banner-cat6 {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/cat6.png");
}
.banner-cat7 {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/cat7.png");
}
.banner-cat8 {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/cat8.png");
}

.offer-b1-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/dash-b1.png");
}
.offer-b2-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/dash-b2.png");
}
.offer-b3-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/dash-b3.png");
}
.offer-b4-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/dash-b4.png");
}
.offer-b5-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/dash-b5.png");
}
.offer-b6-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/dash-b6.png");
}
.offer-b7-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/dash-b7.png");
}
.offer-b8-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/dash-b8.png");
}
.offer-b9-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/dash-b2.png");
}
.offer-b10-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/dash-b9.png");
}

.loan-b1-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/loan-b1.png");
}
.loan-b2-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/loan-b2.png");
}
.loan-b3-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/loan-b3.png");
}
.loan-b4-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/loan-b4.png");
}
.loan-b5-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/loan-b5.png");
}
.loan-b6-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/loan-b6.png");
}
.loan-b7-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/loan-b7.png");
}
.loan-b8-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/loan-b8.png");
}
.loan-b9-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/loan-b2.png");
}
.loan-b10-img {
    background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/loan-b9.png");
}

.loan-list-card-wrapper {
    width: 100%;
    @include flex-row;
    align-items: flex-start;
    margin-top: 4rem;
    flex-wrap: wrap;
}

.loan-list-card {
    min-height: 18rem;
    width: 37rem;
    padding: 1rem 1.5rem .5rem 1.5rem;
    background-color: #081952;
    overflow: hidden;
    margin-right: 1rem;
    margin-bottom: 1rem;
    position: relative;
    border-radius: .8rem;
    z-index: 2;
    cursor: pointer;

    @media (max-width: 1210px) {
        width: 45rem
    }
    @media (max-width: 750px) {
        width: 37rem
    }
    @media (max-width: 730px) {
        width: 45rem;
        margin-right: 0;
    }
    @media (max-width: 450px) {
        width: 100%
    }
}

.loan-list-card-bubble {
    position: absolute;
    right: 2rem;
    top: 2.5rem
}

// ============= Loan Category ============
.loan-category-title {
    @include flex-row;
    width: 50rem;
    align-items: flex-start;
    margin-bottom: 9rem;

    @media (max-width: 1035px) {
        width: 40rem
    }
    @media (max-width: 350px) {
        width: 30rem
    }
}

.loan-category-card, .loan-request-card {
    min-height: 14rem;
    width: 35.8rem;
    padding: 1rem 1.5rem;
    background-color: #081952;
    overflow: hidden;
    margin-right: 1rem;
    margin-bottom: 1rem;
    position: relative;
    border-radius: .4rem;
    z-index: 2;
    cursor: pointer;

    @media (max-width: 1175px) {
        width: 43rem
    }
    @media (max-width: 750px) {
        width: 35.8rem
    }
    @media (max-width: 700px) {
        width: 43rem;
    }
    @media (max-width: 450px) {
        width: 100%;
        margin-right: 0;
    }
}

// ===== Loan Request ===========

.loan-request-search {
    position: absolute;
    top: 1.5rem;
    right: 0rem;
    width: 40rem;
    
    @media (max-width: 1300px) {
        position: relative;
        top: 0;
        right: 0
    }

    @media (max-width: 450px) {
        width: 100%
    }
}

.loan-request-search {
    @media (max-width: 450px) {
        width: 100%
    }
}

.loan-request-card {
    min-height: 7.5rem;
    width: 34rem;

    @media (max-width: 1139px) {
        width: 43rem
    }
    @media (max-width: 750px) {
        width: 35.8rem
    }
    @media (max-width: 700px) {
        width: 43rem;
    }
    @media (max-width: 450px) {
        width: 100%;
        margin-right: 0;
    }
}

.Select.is-open { position: relative; z-index: 1000; }