@import 'src/index.scss';

.settings_home-content-wrapper {
    width: 75rem;
    // margin-top: 3rem;
    padding-left: 2rem;
    position: relative;

    @media (max-width: 1060px) {
        width: 100%;
        padding-right: 2rem;
    }
}

.settings_personal-details-wrapper {
    width: 100%;
    margin-top: 3rem !important;
    padding-left: 2rem !important;
    position: relative;

    @media (max-width: 1432px) {
        width: 75rem !important;
        flex-wrap: wrap !important;
    }

    @media (max-width: 1060px) {
        width: 100% !important;
        padding-right: 2rem !important;
    }
}

.settings-details-header {
    @media (max-width: 380px) {
        width: 100% !important;
    }
}

.setting-user-img {
    @media (max-width: 320px) {
        width: 7rem !important;
     }
    @media (max-width: 300px) {
       width: 8rem !important;
    }
}

.add-left-border {
    border-left: 1px solid #E0E0E0;
}

.settings-icon {
    font-size: 2rem;
    color: #8095E0
}
.setting-card-wrapper {
    @media (max-width: 500px) {
        justify-content: center !important;
    }
}
.settings-card {
    @include flex-row;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: .5rem;
    width: 35rem;
    padding: 2rem;
    margin-bottom: 2rem;
    background-color: #081952;
    transition: all .2s;

    &:hover {
        background-color: #4700E0
    }

    &:nth-child(odd) {
        margin-right: 2rem;
        @media (max-width: 500px) {
            margin-right: 0
        }
    }
}

// Visibility Slider
.visibility-slider-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 4.5rem;
    height: 2.5rem;
    background-color: #00071F;
    border-radius: 10rem;
    overflow: hidden;

    @media (max-width: 338px) {
        margin-top: 2rem;
        margin-left: 4rem
    }
}
.visibility-slider-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 4.5rem;
    height: 2.5rem;
    background-color: #00071F;
    transition: all .2s;
}
.visibility-slider {
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 50%;
    background-color: #8095E0;
    margin-left: .5rem;
    z-index: 2;
    transition: all .4s
}
.visibility-checkbox {
    position: absolute;
    width: 100%;
    height: 2.5rem;
    opacity: 0;
    z-index: 3;
}
.visibility-checkbox:checked ~ .visibility-slider {
    margin-left: 2.5rem;
    background-color: $app-color-green;
}
.visibility-checkbox:checked ~ .visibility-slider-shadow {
    background-color: #fff;
}

.settings-logout {
    width: 100%;
    @include flex-row;
    justify-content: flex-start;
    margin-top: 6rem
}

.reduce-container-padding {
    @media (max-width: 400px) {
        padding: 0 !important
    }
}

.rating-popup-container {
    background: #081952;
    width: 56rem;
    border-radius: 2rem;
    padding: 3rem;
    position: relative;
    margin: 1rem;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    transition: all .2s;
    min-height: 60rem;
    max-height: 90%;

    &::-webkit-scrollbar {
        display: none
    }

    @media (max-width: 700px) {
        width: 90%;
    }
    @media (max-width: 560px) {
        max-height: 95%;
    } 
    @media (max-width: 390px) {
        padding: 3rem 1.5rem
    } 
}
.rating-icon {
    font-size: 3.2rem
}
.comment-input {
    width: 100%;
    border: 1px solid #8095E0;
    border-radius: .8rem;
    min-height: 17rem;
    background-color: transparent;
    margin-top: 1.8rem;
    outline: none;
    color: #fff;
    padding: 1.5rem;
    font-size: 1.4rem;

    &:focus, &:active {
        outline: none
    }
}

.account-settings-container {
    width: 100%;
    @include flex-row;
    position: relative;

    @media (max-width: 1100px) {
        @include flex-column;
        align-items: flex-start;
    }
}
.account-setting-tabs {
    width: 35%;
    margin-top: 5rem;
    height: 100%;
    padding-right: 2rem;

    @media (max-width: 1100px) {
        width: 95%;
        padding-right: 0;
    }
}

.acct-setting-title {
    @media (max-width: 1100px) {
        width: 40rem !important;
    }
}
.account-setting-info {
    width: 52%;
    position: fixed;
    padding-right: 3.5rem;
    padding-left: 3.5rem;
    padding-top: 30rem;
    padding-bottom: 3rem;
    top: 0;
    right: 0;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-left: 1px solid rgba(#2E4DBD, .15);

    &::-webkit-scrollbar {
        display: none
    }

    @media (max-width: 1100px) {
        position: relative;
        padding-right: 0;
        padding-top: 0;
        margin-top: 8rem;
        width: 80%;
        height: auto;
    }
    @media (max-width: 770px) {
        width: 90%;
        margin: 8rem auto 0 auto;
        border-left: none
    }
    @media (max-width: 530px) {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    } 
}
.tabs-wrapper {
    width: 100%;
    padding-left: 5rem;
    margin-top: 6rem;

    @media (max-width: 1265px) {
        padding-left: 3rem
    }
    @media (max-width: 1100px) {
        padding-left: 6rem;
        @include flex-row;
        justify-content: flex-start;
    }

    @media (max-width: 650px) {
        flex-wrap: wrap;
        padding-left: 2rem;
    }

}
.tab-content {
    width: 28rem;
    @include flex-row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem 1.5rem;
    margin-bottom: 2.5rem;
    transition: all .2s;

    @media (max-width: 1265px) {
        width: 95%
    }
    @media (max-width: 1131px) {
        width: 100%
    }

    p, span {
        color: #898989;
        font-weight: 400 
    }
}

.selected-tab {
    border: 1px solid #6DE7B4;
    border-radius: .5rem;
    transition: all .2s;
    
    p {
        color: #fff;
        font-weight: 700
    }
    span {
        color: #fff;
    }
}
.tab-icon {
    color: #6DE7B4
}

.app-passCode {
    width: 4.3rem;
    height: 4.3rem;
    border: 1px solid rgba(128, 149, 224, 0.5);
    border-radius: .4rem;
    font-weight: 700;
    background-color: transparent;
    color: #fff;
    text-align: center;
    font-size: 1.7rem;
    font-weight: 700;
    outline: none;
    display: grid;
    place-items: center;

    @media (max-width: 335px) {
        width: 13%
    }

    &:not(last-child) { 
        margin-right: 1rem
    }
}

.change-code {
    font-size: 1.4rem;
    font-weight: 700;
    text-decoration: underline;
    color: #6DE7B4;
    outline: none;
    cursor: pointer;

    &:hover, &:visited, &:link {
        outline: none
    }

    &:hover {
        color: $app-color-tea;
    }
}

.full-column-flex {
    @media (max-width: 420px) {
        display: flex;
        flex-direction: column !important;
        justify-content: flex-start;
        align-items: flex-start;
    }
}
.full-width-flex {
    @media (max-width: 420px) {
        width: 100% !important;
        margin-right: 100% !important
    }
}

.acct-passcode-wrapper {

    @media (max-width: 335px) {
        width: 100% !important;
    }
}

.text-cursor {
    cursor: pointer !important
}

.account-new-sigature {
    border-bottom: 1px solid #6DE7B4 !important;
    transition: all .2s;

    &:hover {
        border-bottom: 1px solid #FED0B7 !important;
    }
}

.score-container {
    margin-left: 2.5rem !important;
    margin-top: -0.3rem !important;

    @media (max-width: 1432px) {
        width: 75rem !important;;
        margin-left: 0rem !important;
        margin-top: 3rem !important;
    }

    @media (max-width: 1060px) {
        width: 100% !important;
    }

    @media (max-width: 500px) {
        //width: 100% !important;
    }
}

.score-pointer {
    position: absolute !important;
    z-index: 2 !important;
    height: 1.7rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.account-strict-action {
    @media (max-width: 416px) {
        button {
            width: 100% !important;
        }
    }
}