@import 'src/index.scss';

.transaction-data-wrapper {

    @media (max-width: 977px) {
        //width: 80% !important;
        margin: 0 auto !important
    }

    @media (max-width: 770px) {
        width: 95% !important;
    }
    @media (max-width: 380px) {
        width: 100% !important;
    }
}

.trans-screen-container {
    @media (max-width: 770px) {
        padding: 2rem 0 !important
    }
}

.filter-search-transaction-wrapper {
    justify-content: space-between !important;
    margin-bottom: 4rem;

    @media (max-width: 540px) {
        flex-direction: column !important; 
        //align-items: center !important;
    }
}
.filter-transaction-wrapper {
    @media (max-width: 540px) {
        margin-top: 2rem !important;
    }

    @media (max-width: 320px) {
        flex-direction: column !important;
    }
}
.search-transaction-wrapper {
    width: 30rem;

    @media (max-width: 990px) {
        width: 20rem
    }

    @media (max-width: 770px) {
        width: 25rem
    }

    @media (max-width: 540px) {
        width: 30rem
    }
}
.trans-date {
    width: 13rem !important;
    margin: 0 !important;
    &:first-child {
        margin-right: 0.8rem !important;
    }

    input {
        height: 4rem !important;
        border: 1px solid #4B5D99 !important;
        border-radius: 0.4rem !important;
    }

    label {
        font-weight: 400 !important;
        font-size: 1.3rem !important;
    }

    @media (max-width: 320px) {
        margin-bottom: 1rem !important;
    }
}
.trans-filter-icon {
    @media (max-width: 380px) {
        margin-top: 0 !important;
    }
}