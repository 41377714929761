@import 'src/index.scss'; 

.onboarding-type-container {
    width: 65rem;
    margin: 8rem auto 5rem auto;
    height: 40rem;
    @include flex-column;
    align-items: center;

    @media (max-width: 1070px) {
        width: 80%
    }
    @media (max-width: 450px) {
        width: 95%
    }
}

.onboarding-type {
    width: 100%;
    @include flex-row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 4rem;
    margin-bottom: 3rem;

    @media (max-width: 370px) {
        flex-wrap: wrap;
    }
}
.onboard-type-wrap {
    width: 48.5%;
    border-radius: .8rem;
    height: 26rem;
    padding: 3rem 2rem;
    background: radial-gradient(107.96% 90.58% at 91.16% 100%, #63BEDB 0%, #56E77E 33.93%, #6DE7B4 52.08%, #E2FCC2 100%);

    @media (max-width: 370px) {
        width: 95%;
        margin-bottom: 2rem
    }

    &:nth-child(odd) {
        margin-right: 3%;
        background: radial-gradient(123.45% 84.23% at 90.24% 96.92%, #FD5454 0%, #FF9061 29.17%, #FFBA7B 53.33%, #FFCEC4 100%);

        @media (max-width: 370px) {
            margin-right: 0;
        }
    } 
}
.progress-bar-wrapper {
    @media (max-width: 750px) {
        padding-left: 0 !important;
    }
}
.title-width-auto {
    font-size: 3rem;
    width: 41rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0rem;

    @media (max-width: 370px) {
        width: auto !important;
    }
}

.onboarding-container {
    margin: 6rem 7rem 2rem 7rem;
    width: 68rem;

    @media (max-width: 1189px) {
        margin: 6rem 2rem 2rem 2rem;
    }
    @media (max-width: 1115px) {
        width: 95%
    }
    @media (max-width: 750px) {
        margin-top: 8rem
    }
    @media (max-width: 360px) {
        width: 100%;
        margin: 6rem 0rem 2rem 0rem;
    }
}
.idcard-wrapper {
    width: 50rem;
    margin: 0 auto;
    position: relative;

    @media (max-width: 980px) {
        width: 100%
    }
    @media (max-width: 750px) {
        width: 50rem
    }
    @media (max-width: 530px) {
        width: 100%
    }
}
.idCard-upload-block {
    width: 100%;
    padding: 3rem;
    border: 1px dashed rgba(193, 205, 246, 0.8);
    border-radius: .8rem;
}
.idCard-container {
    width: 90%;
    margin: 1rem auto;
    height: 22rem;
    border-radius: .8rem;

    @media (max-width: 416px) {
        width: 100%;
        height: 18rem
    }
}
.idcard-photo {
    min-width: 100%;
    max-width: 100%;
    height: 100%;
}

.idcard-upload-level {
    width: 80%;
    height: 100%;
    background-color: $app-color-green
}
.idcard-input {
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute
}
.signature-container {
    @include flex-column;
    justify-content: center;
    align-items: center;
    height: 25rem;
}
.signature-photo {
    max-width: 100%;
    max-height: 100%
}

.faceScan-wrapper {
    width: 27rem;
    height: 27rem;
    padding: 1rem;
    margin-left: 5rem;
    margin-right: 5rem;
    border: .2rem dashed #141B34 !important;
    //border-radius: 50%;

    @media (max-width: 430px) {
        margin-left: 0;
    }
    @media (max-width: 380px) {
        margin-right: 0;
    }
}
.faceScan-camera {
    width: 100%;
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
    //border-radius: 50% !important
}

.rm-btn-margin {
    @media (max-width: 430px) {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
.faceScan-container {
    @media (max-width: 380px) {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
}

.transaction-pin-container {
    background: #081952;
    min-height: 67rem;
    width: 100rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 2rem 2rem 2rem;
    position: relative;
    max-height: 90%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    transition: all .2s;

    &::-webkit-scrollbar {
        display: none
    }

    @media (max-width: 1050px) {
        width: 90%
    }
}

.transaction-pin-title {
    font-weight: 700;
    font-size: 2.8rem;
    color: #FED0B7;
    margin-top: 4rem;
    text-align: center;
}

.transaction-pin-input {
    border: 1px solid #fff;
    border-radius: .8rem;
    background-color: transparent;
    color: #fff;
    outline: none;
    height: 6rem;
    width: 6rem;
    margin-right: 0.8rem;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    display: grid;
    place-items: center;

    &:last-child {
        margin-right: 0
    }
}
