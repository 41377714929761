@import 'src/index.scss';

.wait-loader-gif {
    @media (max-width: 371px) {
        margin-top: -17rem !important;
    }
    @media (max-width: 331px) {
        margin-top: -13rem !important;
    }
}

.progress-waiting-bar {
    @media (max-width: 371px) {
        margin-top: -8rem !important;
    }
    @media (max-width: 330px) {
        margin-top: -5rem !important;
        width: 27rem !important;
    }
}

.fun-fact-container {
    @media (max-width: 440px) {
        width: 90% !important;
    }
}