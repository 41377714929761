@import 'src/index.scss';

.responder-cancel {
    color: #FED0B7;
    font-size: 1.7rem;
    position: absolute;
    top: 4rem;
    right: 4rem
}
.responder-btn {
    
}