@import 'src/index.scss';

.img-404 {
    @media (max-width: 500px) {
        transform: scale(0.75);
    }

    @media (max-width: 350px) {
        transform: scale(0.6);
    }
}