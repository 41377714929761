@import 'src/index.scss';

.loan-tenor-types {
    width: 100%;
    @include flex-row;
    flex-wrap: wrap;
    margin-top: .2rem;
}
.loan-rate-divider {
    border-bottom: 1px solid #1E3177;
    margin: 4rem 0 4rem 0;
}