@import 'src/index.scss';

.complete-profile-wrapper {
    width: 65rem;
    border: 1px dashed #2E4DBD;
    border-radius: .4rem;
    padding: 1.5rem 2rem;
    margin: 0rem 0 1rem 2rem; // margin-top: 2rem

    @media (max-width: 1270px) {
        margin: 2rem auto;
    }
    @media (max-width: 580px) {
        width: 95%;
        //flex-wrap: wrap
    }
}
.complete-profile-container {
    // width: 65rem;
    // border: 1px dashed #2E4DBD;
    // border-radius: .4rem;
    // padding: 2rem;
    // margin: 0rem 0 1rem 2rem; // margin-top: 2rem
    @include flex-row;
    align-items: flex-end;
    justify-content: space-between;

    // @media (max-width: 1270px) {
    //     margin: 2rem auto;
    // }
    @media (max-width: 580px) {
        // width: 95%;
        flex-wrap: wrap
    }
}

.complete-level-container {
    @media (max-width: 550px) {
        width: 60% !important
    }

    @media (max-width: 402px) {
        width: 95% !important
    }
}

.complete-level {
    @media (max-width: 550px) {
        width: 100% !important
    }
}

.complete-level-text {
    @media (max-width: 402px) {
        margin-top: 2rem !important;
        width: 100% !important;
        text-align: center !important
    }
}

.wallet-crendly-logo {
    @media (max-width: 400px) {
        display: none
    }
}

.wallet-action-btn-wrapper {
    width: 100%;
    @include flex-row;
    justify-content: flex-end;
    margin: 2rem 0 1rem 0;

    @media (max-width: 520px) {
        justify-content: center
    }
}
.wallet-action-btn1, .wallet-action-btn2 {

    .wallet-action-btn-icon {
        font-size: 2rem;
        margin-right: 1rem;
    }

    span {
        font-size: 1.6rem;
        font-weight: 700;
    }
}

.home-content-row-container {
    width: 100%;
    padding: 3rem 0 2rem 2rem;
    @include flex-row;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1270px) {
        @include flex-column;
        justify-content: center;
        align-items: center
    }

    @media (max-width: 770px) {
        padding-left: 0
    }
}
.home-content-row-1 {
    width: 67%;
    margin-right: 3%;

    @media (max-width: 1270px) {
        width: 75%;
        margin-right: 0
    }

    @media (max-width: 1050px) {
        width: 90%;
    }

    @media (max-width: 870px) {
        width: 95%;
    }

    @media (max-width: 318px) {
        width: 99%;
    }
}
.home-content-row-2 {
    width: 30%;

    @media (max-width: 1270px) {
        width: 75%;
    }

    @media (max-width: 1050px) {
        width: 90%;
    }
    
    @media (max-width: 870px) {
        width: 95%;
    }

    @media (max-width: 318px) {
        width: 99%;
    }
}
.notif-wrapper {
    margin-top: 2rem !important;
    align-items: flex-end !important;
    @media (max-width: 1270px) {
        padding-right: 5% !important
    }
}
.wallet-balance-wrapper {
    width: 100%;
    margin-bottom: 3rem;

    @media (max-width: 520px) {
        width: 100%;
    }

    label {
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
}
.wallet-balance {
    height: 20rem;
    padding: 2rem 1.5rem;
    background: #081952;
    border-radius: .8rem;
    position: relative;
    overflow: hidden;

    @media (max-width: 550px) {
        height: 25rem;
        //padding-top: 5rem;
    }

    span {
        font-size: 1.4rem;
    }

    p {
        font-size: 3rem;
        font-weight: 700;
        margin-top: 2rem
    }
}
.wallet-c_logo {

    @media (max-width: 600px) {
        bottom: -5rem !important;
        right: -1rem !important
    }
    @media (max-width: 479px) {
        right: -1.5rem !important
    }

    img {
        width: 16rem;

        @media (max-width: 479px) {
            width: 10rem
        }
    }
   
}
.loan-option-card-wrapper {
    width: 100%;
    @include flex-row;
    margin-bottom: 3rem;

    @media (max-width: 520px) {
        @include flex-column;
        align-items: center;
        justify-content: center
    }
}
.loan-option-card-1, .loan-option-card-2 {
    width: 49%;
    height: 20rem;
    padding: 1.5rem;
    background: radial-gradient(123.45% 84.23% at 90.24% 96.92%, #FD5454 0%, #FF9061 29.17%, #FFBA7B 53.33%, #FFCEC4 100%);
    border-radius: .8rem;
    margin-right: 2%;

    @media (max-width: 520px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;
    }

    p {
        font-size: 2.2rem;
        font-weight: 700;
        color: #131176;
    }
}

.loan-option-card-2 {
    background: radial-gradient(107.96% 90.58% at 91.16% 100%, #63BEDB 0%, #56E77E 33.93%, #6DE7B4 52.08%, #E2FCC2 100%);
    margin-right: 0;
}

.loan-option-card-open {
    width: 49%;
    height: 20rem;
    padding: 1.5rem;
    border-radius: .8rem;
    background-color: #122C85;
    margin-right: 0;
    position: relative;

    @media (max-width: 520px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;
    }
}

.loan-avatar {
    transform: scale(.8)
}
.loan-option-card-text-wrapper {
    width: 100%;
    @include flex-row;
    justify-content: space-between;
    align-items: flex-start;

    p {
        font-size: 1.2rem;
        color: #070647;
        width: 80%;
        font-weight: 400;
    }

    .icon-chevron-right {
        font-size: 2rem;
        color: #070647;
        margin-right: 1.5rem;
    }
}

.trends-wrapper {
    width: 100%;
    margin-bottom: 3rem;

    label {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
}
.trends {
    height: 43rem;
    padding: 5rem 1.5rem 2rem 1.5rem;
    background: #081952;
    border-radius: .8rem;
    position: relative;

    @media (max-width: 1270px) {
        height: 37rem;
    }
}
.trend-chart-toggle {
    width: 100%;
    position: absolute;
    top: 1.5rem;
    left: 2rem;
}

.score-wrapper {
    width: 100%;
    height: 20rem;
    margin-top: 3.5rem;
    border-radius: .8rem;
    margin-bottom: 3rem;
}
.score-content-wrapper {
    width: 100%;
    height: 9.5rem;
    padding: 1.5rem;
    border: 1px solid #081952;
    border-radius: .4rem;
    @include flex-row;
    align-items: flex-start;
    position: relative;

    &:first-child {
        margin-bottom: 1rem;
    }
}

.score-data-container {
    margin-left: 1.5rem;
    margin-top: -.8rem;

    @media (max-width: 1270px) {
        margin-left: 4rem;
    }

    @media (max-width: 330px) {
        margin-left: 1.5rem;
    }

    span {
        font-size: 1.4rem;
        font-weight: 700;
    }

}
.score-data-wrapper {
    @include flex-row;
    align-items: flex-start;
    margin-top: 1rem;

    .score-data {
        @include flex-row;
        align-items: flex-start;
        width: 7rem;

        &:first-child {
            margin-right: 3rem;

            @media (max-width: 1270px) {
                margin-right: 5rem
            }

            @media (max-width: 330px) {
                margin-right: 3rem;
            }
        }

        .score-data-point {
            font-size: 1.6rem;
            font-weight: 700;
            padding-bottom: .05rem;
            border-bottom: .3rem solid #FED0B7
        }
        .score-data-label {
            font-size: 1rem;
            font-weight: 600;
            width: 3rem;
            padding-left: 1rem; 
        }
        .purple-border {
            border-bottom: .3rem solid #4700E0
        }
        .pink-border {
            border-bottom: .3rem solid #FF0062
        }
    }
}

.quick-box-container {
    margin-bottom: 1.5rem;
    width: 100%;

    span {
        cursor: pointer
    }
}
.quick-box-title {
    font-size: 1.6rem;
    font-weight: 700
}
.quick-box-wrapper {
    @include flex-row;
    width: 100%;
    margin-top: 2rem;
    flex-wrap: wrap;
}
.quick-box {
    width: 49%;
    height: 6.8rem;
    background: #4700E0;
    border-radius: .8rem;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
    @include flex-row;
    color: #E2E2E2;
    transition: all .2s

    span {
        font-size: 1.4rem;
        font-weight: 700;
        cursor: pointer;
    }

    &:hover {
        background: #081952
    }
}
.box-style-plus {
    margin-right: 2% !important
}

.box-icon-wrapper {
    height: 4.5rem;
    width: 4.5rem;
    background-color: rgba(#fff, .1);
    @include flex-row;
    justify-content: center;
    border-radius: 50%;
    margin-right: 1rem
}
.trans-activity-wrapper {
    width: 100%;
    margin-bottom: 3rem;
}
.trans-label-wrapper {
    @include flex-row;
    justify-content: space-between;

    label {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 1rem;

        &:last-child {
            color: $app-color-green;
            border-bottom: 1px solid $app-color-green
        }
    }
}
.trans-content-wrapper {
    //height: 43rem;
    padding: 1.5rem .8rem 1.3rem .8rem;
    background: #081952;
    border-radius: .8rem;
}
.trans-content {
    width: 100%;
    padding: 1.2rem 1rem;
    border-bottom: 1px solid #13276F;
    @include flex-row;
    align-items: flex-start;
    justify-content: space-between;

    &:last-child {
        border-bottom: none
    }
}
.content-label-wrapper {
    @include flex-row;
}
.trans-content-label {
    margin-left: 1.5rem;

    label {
        font-size: 1.4rem;
        font-weight: 700;
        display: block;
        margin-bottom: -.5rem;
    }
    span {
        font-size: 1rem;
        font-weight: 400;
        color: #E2E2E2;
    }
}

.content-amt-wrapper {
    width: 12rem;
    text-align: right;
    label {
        font-size: 1.6rem;
        font-weight: 700;
        color: #F2EAE0;
        display: block;
        margin-bottom: -.5rem;
    }
    span {
        font-size: 1rem;
        font-weight: 400;
        color: #FF0062;
    }
}

.loan-category-wrapper {
    width: 100%;
    margin-bottom: 3rem;
    padding-left: 2rem;

    @media (max-width: 450px) {
        padding-left: 0
    }

    label {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
}
.loan-category {
    width: 100%;
    @include flex-row;
    flex-wrap: wrap;

    // @media (max-width: 1200px) {
    //     flex-wrap: wrap;
    // }

    @media (max-width: 503px) {
        justify-content: center;
    }
}
.loan-category-content {
    height: 31rem; // 33rem
    width: 27rem; // 23.5%
    background-size: cover;
    background-position: top;
    border-radius: .8rem;
    margin-right: 1.5rem;
    padding: 1.2rem;
    margin-bottom: 1.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;

    // @media (max-width: 1200px) {
    //     width: 30%;
    //     margin-bottom: 1.5rem
    // }

    // @media (max-width: 1000px) {
    //     width: 47%;
    //     margin-bottom: 1.5rem
    // }

    @media (max-width: 503px) {
        width: 100% !important;
        height: 20rem;
        margin-right: 0;
        background-position: center;
    }

    &:last-child {
        margin-right: 0;
    }
}
.offer-category1 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, 0.8)), url("../../../assets/img/dash1.png");
}
.offer-category2 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, 0.5)), url("../../../assets/img/dash2.png");
}
.offer-category3 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, 0.5)), url("../../../assets/img/dash3.png");
}
.offer-category4 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, .5)), url("../../../assets/img/dash4.png");
}
.offer-category5 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, 0.5)), url("../../../assets/img/dash5.png");
}
.offer-category6 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, .5)), url("../../../assets/img/dash6.png");
}
.offer-category7 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, .5)), url("../../../assets/img/dash7.png");
}
.offer-category8 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, .5)), url("../../../assets/img/dash8.png");
}
.offer-category9 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, 0.5)), url("../../../assets/img/dash2.png");
}
.offer-category10 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, .5)), url("../../../assets/img/dash9.png");
}


.loan-category1 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, 0.8)), url("../../../assets/img/loan1.png");
}
.loan-category2 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, 0.5)), url("../../../assets/img/loan2.png");
}
.loan-category3 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, 0.5)), url("../../../assets/img/loan3.png");
}
.loan-category4 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, .5)), url("../../../assets/img/loan4.png");
}
.loan-category5 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, 0.5)), url("../../../assets/img/loan5.png");
}
.loan-category6 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, .5)), url("../../../assets/img/loan6.png");
}
.loan-category7 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, .5)), url("../../../assets/img/loan7.png");
}
.loan-category8 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, .5)), url("../../../assets/img/loan8.png");
}
.loan-category9 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, 0.5)), url("../../../assets/img/loan2.png");
}
.loan-category10 {
    background-image: linear-gradient(to right bottom, rgba(#000, .4), rgba(#000, .5)), url("../../../assets/img/loan9.png");
}


.loan-category_text-wrapper {
    width: 100%;
    position: absolute;
    bottom: 2.5rem;
    left: 1.2rem;

    p {
        width: 18rem;
        font-size: 1.4rem;
        font-weight: 700;
        margin-bottom: 3rem;
    }

    button {
        width: 9rem;
        height: 3.5rem;
        border: none;
        border-radius: .8rem;
        background-color: #FFFFFF;
        font-size: 1.2rem;
        font-weight: bold;
        color: #000;
        outline: none;
        transition: all .2s;

        &:hover, &:focus {
            background-color: $app-color-green;
            color: #fff;
            outline: none
        }
    }
}

// COMMUNITY

.community-wrapper {
    width: 100%;
    margin-bottom: 3rem;
    padding-left: 2rem;

    @media (max-width: 450px) {
        padding-left: 0
    }

    label {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
}
.community {
    width: 100%;
    @include flex-row;

    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }

    @media (max-width: 450px) {
        justify-content: center;
    }
}
.community-content {
    height: 30rem;
    width: 32%;
    background-size: cover;
    background-position: top;
    border-radius: .8rem;
    margin-right: 2%;
    padding: 1.2rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;

    @media (max-width: 1140px) {
        width: 42%;
        margin-bottom: 1.5rem
    }

    @media (max-width: 1000px) {
        width: 48%;
        margin-bottom: 1.5rem
    }

    @media (max-width: 450px) {
        width: 100%;
        height: 22rem;
        margin-right: 0;
        background-position: center;
    }

    &:last-child {
        margin-right: 0;
    }
}
.community1 {
    background-image: url("../../../assets/img/community-1.png");
}
.community2 {
    background-image: url("../../../assets/img/community-2.png");
}
.community3 {
    background-image: url("../../../assets/img/community-3.png");
}

.community_text-wrapper {
    width: 100%;
    position: absolute;
    top: 2.5rem;
    left: 1.2rem;

    p {
        width: 18rem;
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 3rem;
    }

    button {
        width: 11rem;
        height: 4.5rem;
        border: none;
        border-radius: .8rem;
        background-color: #FFFFFF;
        font-size: 1.4rem;
        font-weight: bold;
        color: #000;
        outline: none;
        transition: all .2s;

        &:hover, &:focus {
            background-color: $app-color-green;
            color: #fff;
            outline: none
        }
    }
}

//========WALLET POPUP =================

.popup-title-wrapper {
    @include flex-row;
    justify-content: space-between;
    margin-top: 3rem;
    margin-bottom: 4rem;

    h4 {
        font-size: 2rem;
        font-weight: 700;
    }

    i {
        color: #FED0B7;
        font-size: 1.7rem;
    }
}
.popup-grey-container {
    width: 100%;
    padding: 3rem;
    border-radius: 0rem 0rem 3.2rem 3.2rem;
    background-color: #0F2059;
}

.popup-grey-container-new {
    width: 100%;
    padding: 3rem 3rem 0 3rem;
}

.popup-main-container {
    width: 100%;
    padding: 2rem;
}
.divider-line {
    width: 100%;
    border: 1px solid #2E4DBD;
    margin-bottom: 3rem;
}
.wallet-type-container {
    width: 100%;
    margin-bottom: 3.8rem;
}
.wallet-type-label {
    margin: 0;
    padding-bottom: 1rem;
}
// ======= TOGGLER ==============
.wallet-type-toggler {
    border: 1px solid #FED0B7;
    border-radius: .8rem;
    background-color: transparent;
    padding: .3rem;
    color: #fff;
    height: 5rem;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.toggler-checkbox {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 50%;
    z-index: 3;
    opacity: 0
}
.toggler-checkbox:checked {
    left: 0;
}
.toggler-checkbox:checked ~ .wallet-type-slider {
    margin-left: 50%;
}
.toggler-checkbox:checked ~ .wallet-type-text {
    .wallet-type-1 {
        color: #fff;
    }
    .wallet-type-2 {
        color: #000;
    }
}
.wallet-type-slider {
    width: 50%;
    height: 100%;
    background-color: #FED0B7;
    border-radius: .8rem;
    margin-left: 0;
    transition: all .3s;
}
.wallet-type-text {
    @include flex-row;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    text-align: center;
    font-weight: 700;

    .wallet-type-1 {
        color: #000;
        width: 50%
    }
    .wallet-type-2 {
        color: #fff;
        width: 50%

    }
}
.payment-notice {
    font-weight: 500;
}
.payment-btn {
    width: 24rem;
    height: 5rem;
    background: #6DE7B4;
    border-radius: 8px;
    outline: none;
    transition: all .2s;
    color: #00071F;
    font-weight: 700;
    margin-top: 4rem;

    &:hover, &:focus {
        background: #FED0B7;
        outline: none
    }
}

.account-option-wrapper {
    width: 100%;
    padding: 0rem 1.3rem 3rem 1.3rem;
    border: 1px dashed #8095E0;
    border-radius: .8rem;
    margin-bottom: 3rem;
}
.account-option-title {
    width: 100%;
    padding: 2rem 0;
    border-bottom: 1px solid rgba(128, 149, 224, 0.3);
}
.account-option-details {
    width: 100%;
    margin-top: 1rem;
}
.flex-row-space-between {
    @include flex-row;
    justify-content: space-between;
    align-items: flex-start;
}
.account-details-title {
    width: 60%;
    margin-right: 3rem;
}
.account-details-info {
    width: 40%;

    i {
        font-size: 1.8rem;
        margin-left: 1.5rem;
        color: #FED0B7
    }
}

// ======= TOGGLER ==============
.calc-type-toggler {
    border-bottom: 1px solid #0D267B;
    padding: .3rem;
    color: #fff;
    height: 5rem;
    width: 100%;
    position: relative;
}
.calc-toggler-checkbox {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 50%;
    z-index: 3;
    opacity: 0
}
.calc-toggler-checkbox:checked {
    left: 0;
}
.calc-toggler-checkbox:checked ~ .calc-type-slider {
    left: 50%;
}
.calc-toggler-checkbox:checked ~ .calc-type-text {
    .calc-type-1 {
        color: #A5A5A5;
    }
    .calc-type-2 {
        color: #fff;
    }
}
.calc-type-slider {
    width: 50%;
    height: .4rem;
    background-color: #FED0B7;
    margin-left: 0;
    transition: all .3s;
    position: absolute;
    bottom: -.25rem;
    left: 0
}
.calc-type-text {
    @include flex-row;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    text-align: center;
    font-weight: 700;

    .calc-type-1 {
        color: #fff;
        width: 50%
    }
    .calc-type-2 {
        color: #A5A5A5;
        width: 50%

    }
}

.calc-type-amount {
    width: 100%;
    @include flex-column;
    justify-content: center;
    align-items: center;
    margin-top: 4.5rem;
}
.calc-loan-data {
    @include flex-row;
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 1rem;
    justify-content: space-between;

    @media (max-width: 350px) {
        justify-content: center;
        flex-wrap: wrap;
    }
}

.loan-calc-form {
    padding: 3rem;
    margin-top: 2rem
}
.loan-calc-form-new {
    padding: 1rem 3rem 3rem 3rem;
    margin-top: 2rem
}
.data-with-label {

    @media (max-width: 350px) {
        width: 50%;
        margin-top: 1rem;

        &:last-child {
            margin-top: 2.5rem
        }
    }
}
.card-center-icon {
    margin-bottom: 4rem;
}
.account-dash-wrapper {
    width: 100%;
    border: 1px dashed #8095E0;
    border-radius: .8rem;
    padding: 1.5rem;
    @include flex-row;
    justify-content: space-between;
}
.withdraw-passCode {
    width: 4.7rem;
    height: 4.8rem;
    border: 1px solid rgba(254, 208, 183, 0.5);
    border-radius: .4rem;
    background-color: transparent;
    color: #fff;
    text-align: center;
    font-size: 1.7rem;
    font-weight: 700;
    outline: none;
    display: grid;
    place-items: center;

    &:not(last-child) { 
        margin-right: 1rem
    }
}
.withdraw-passcode-wrapper {
    @media (max-width: 350px) {
        flex-direction: column !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }
}

.fixed-chat-icon-wrapper {
    position: fixed !important;
    bottom: 8rem;
    right: 4rem;
    z-index: 3;
    transition: all .2s;

    &:hover {
        transform: scale(1.1);
    }
}

.hover-scale-up-icon {
    transition: all .2s;
    cursor: pointer;
    
    &:hover {
        transform: scale(1.1);
    }
}

.notif-req-text {
    @media (max-width: 490px) {
        width: 97% !important;
    }
}
.notif-offer-container {
    @media (max-width: 490px) {
        flex-direction: column-reverse !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        margin-bottom: 1rem;
    }

    div {
        @media (max-width: 490px) {
            align-self: flex-end !important;
            margin-bottom: 0.7rem;
        }
    }
}

.loan-list-banner-plus {
    width: 100%;
    height: 28rem;
    background: #E2E2E2;
    //background-image: linear-gradient(180deg, rgba(0, 7, 31, 0.656) 0%, rgba(0, 7, 31, 0.488) 100%), url("../../../assets/img/community-7.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    border-radius: .8rem;
    padding: 2.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    margin: 3rem 0; 
    @include flex-column;
    justify-content: center;

    p {
        width: 45% !important;
        line-height: 3.2rem;

        @media (max-width: 580px) {
            width: 70% !important;
        }
        @media (max-width: 390px) {
            width: 95% !important;
        }
    }
    span {
        width: 45% !important;

        @media (max-width: 580px) {
            width: 70% !important;
        }
        @media (max-width: 390px) {
            width: 95% !important;
        }
    }
}
.loan-list-card-wrapper {
    width: 100%;
    @include flex-row;
    align-items: flex-start;
    margin-top: 4rem;
    flex-wrap: wrap;
}
// .loan-list-card-bubble {
//     position: absolute;
//     right: 2.5rem;
//     top: 2.5rem
// }

// .loan-list-card {
//     min-height: 18rem;
//     width: 37rem;
//     padding: 1rem 1.5rem .5rem 1.5rem;
//     background-color: #081952;
//     overflow: hidden;
//     margin-right: 1rem;
//     margin-bottom: 1rem;
//     position: relative;
//     border-radius: .8rem;
//     z-index: 2;
//     cursor: pointer;

//     @media (max-width: 1210px) {
//         width: 45rem
//     }
//     @media (max-width: 750px) {
//         width: 37rem
//     }
//     @media (max-width: 730px) {
//         width: 45rem;
//         margin-right: 0;
//     }
//     @media (max-width: 450px) {
//         width: 100%
//     }
// }
.captcha-wrapper {
    @media (max-width: 900px) {
        width: 37rem !important
    }
    @media (max-width: 600px) {
        width: 39.2rem !important;
        transform: scale(0.8) !important;
        margin-left: -3.5rem !important;
    }
    @media (max-width: 300px) {
        transform: scale(0.7) !important;
        margin-left: -4.5rem !important;
    }
}
.tooltip-style {
    background-color: #081952 !important;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5) !important;
    border-radius: 4px;
    font-size: 1.2rem !important;
    font-weight: 400 !important;
    //text-align: left !important;
}
.my-tooltip {
    width: 30rem !important;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #081952 !important;
}
.my-tooltip .multi-line {
    text-align: left !important;
 }
.balance-type-wrapper {
    @media (max-width: 550px) {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
}
.wallet-home-btns {
    @media (max-width: 550px) {
        margin-top: 2.5rem !important;
    }
}
.total-balance-container {
    margin-left: 1.8rem !important;
    padding-left: 2.8rem !important;
    border-left: 1px solid #FFFFFF !important;

    @media (max-width: 550px) {
        border-left: none !important;
        margin-left: 0 !important;
        padding-left: 0 !important;
        margin-top: 1rem !important;
    }
}

.hide-tooltip {
    @media (max-width: 550px) {
        display: none !important;
    }
}

.toggle-browse-type-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;

    @media (max-width: 1200px) {
        width: 72%;
    }

    @media (max-width: 560px) {
        width: 60%;
    }

    @media (max-width: 340px) {
        width: 50%;
    }

    @media (max-width: 312px) {
        width: 45%;
    }

    @media (max-width: 295px) {
        width: 45%;
    }
}
.browse-type-text-container {
    width: auto !important
}
.browse-type-divider {
    width: 48rem;
    border: 1px solid #2E4DBD;

    @media (max-width: 800px) {
        display: none;
    }
}

.browse-toggle-abs {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.setting-id-card {
    width: 32rem !important;
    height: 18rem !important;

    @media (max-width: 330px) {
        width: 25rem !important;
        height: 16rem !important;
    }
}

.setting-signature {
    padding: 1.5rem !important;
    border: 1px dashed rgba(193, 205, 246, 0.8) !important;
    border-radius: .8rem !important;
}

.complete-profile-popup-container {
    background: #081952;
    width: 70rem;
    border-radius: 2rem;
    padding: 3rem;
    position: relative;
    margin: 1rem;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    transition: all .2s;
    min-height: 45rem;
    max-height: 90%;

    &::-webkit-scrollbar {
        display: none
    }

    @media (max-width: 700px) {
        width: 90%;
    }
    @media (max-width: 560px) {
        //max-height: 95%;
    } 
    @media (max-width: 390px) {
        //padding: 3rem 1.5rem
    } 
}

.profile-to-complete-card {
    width: 47.5% !important;
    
    &:nth-child(odd) {
        margin-right: 3.5% !important
    }

    .profile-complete-icon {
        font-size: 1.5rem; 
        color: #0930B6;
    }

    &:hover {
        background-color: #4700E0 !important;
        
        .profile-complete-icon {
            color: #ffffff !important;
        }
    }

    @media (max-width: 530px) {
        width: 100% !important;
        &:nth-child(odd) {
            margin-right: 0 !important
        }
    }
}