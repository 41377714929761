@import 'src/index.scss';

.loan-back-icon {
    font-size: 3rem;
    color: $app-color-green;
    margin-right: 2.5rem;
    transition: all .2s;

    &:hover {
        color: $app-color-tea
    }
}
.loan-type-title-content-plus {
    margin-top: 3rem;

    p {
        font-size: 2.2rem;
        font-weight: 700;
    }
    span {
        font-size: 1.4rem;
    }
}

.loan-horizontal-bar {
    width: 100%;
    height: 6rem;
    background-color: #4701E0;
    border-radius: .8rem;
    margin-bottom: 6rem
}