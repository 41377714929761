@import 'src/index.scss';

.dashboard-container {
    width: 100%;
    //height: 100%;
    padding-top: 8rem;
    padding-left: 23rem;
    background-color: $app-background-light;

    &::-webkit-scrollbar {
        display: none
    }

    @media (max-width: 770px) {
        padding-left: 0;
    }
}

.dashboard-modules {
    // width: 100%;
    // height: 100%;
}