@import 'src/index.scss';

@keyframes slideInRight {
    0% {
        right: -50%;
        width: 0;
    }
    100% {
        right: 50%;
    }
}

.notifier-container {
    width: 45rem;
    min-height: 8.4rem;
    display: grid;
    place-items: center;
    padding: 1.8rem 2.5rem 1.8rem 1.5rem !important;
    position: fixed;
    top: 3rem;
    left: 0;
    z-index: 20 !important;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 80px 10px rgba(0, 0, 0, 0.4);
    transition: all 1s;
    word-wrap: break-word !important;

    @media (max-width: 500px) {
        width: 40rem
    }
    @media (max-width: 325px) {
        width: 36rem
    }
}

.notifier-text {
    font-size: 1.5rem;
    padding-left: 3rem;
    margin: 0 !important;
    //font-weight: 500;
}
.message-icon {
    padding-right: 2rem !important;
    font-size: 3.5rem;
    width: 6rem;
}

.message-icon-cancel {
    position: absolute;
    top: 50%;
    right: 1.3rem;
    font-size: 2.2rem;
    transform: translateY(-50%);
    transition: all .2s;

    &:hover {
        color: #FF6062
    }
}
.message-notif-text {
    @media (max-width: 500px) {
        width: 26rem !important
    }
    @media (max-width: 325px) {
        width: 22rem !important
    }
}